import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { useAuth } from "../contexts/AuthContext";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import Homepage from "../pages/Homepage";
import Loginpage from "../pages/Loginpage";
import NotfoundPage from "../pages/NotfoundPage";
import Profilepage from "../pages/Profilepage";
import Registerpage from "../pages/Registerpage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import ContractsManagment from "../pages/ContractsManagment";
import PlayerManagement from "../pages/PlayerManagement";
import PlayerPage from "../pages/PlayerPage";
import PlayerDataDisplay from "../pages/PlayerDataDisplay";
import CB from "../pages/CB";
import LandingPage from "../pages/LandingPage";
import BackgroundWrapper from "../components/BackgroundWrapper";
import Tableau from "../pages/Tableau";
import Shineyapp from "../pages/Shineyapp";
import Datawrapper from "../pages/Datawrapper";
import University from "../pages/University";
import DemoPage from "../pages/DemoRequest";
import AIChat from "../pages/AIChat";

export default function AppRouter(props) {
  return (
    <Router>
      <BackgroundWrapper>
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route
            path="/login"
            element={<ProtectedElement element={<Loginpage />} />}
          />
          <Route
            path="/register"
            element={<ProtectedElement element={<Registerpage />} />}
          />
          <Route
            path="/demo"
            element={<ProtectedElement element={<DemoPage />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedElement element={<Profilepage />} />}
          />
          <Route
            path="/ContractsManagment"
            element={<ProtectedElement element={<ContractsManagment />} />}
          />
          <Route
            path="/PlayerManagement"
            element={<ProtectedElement element={<PlayerManagement />} />}
          />
          <Route path="/CB" element={<ProtectedElement element={<CB />} />} />
          <Route
            path="/PlayerPage"
            element={<ProtectedElement element={<PlayerPage />} />}
          />
          <Route
            path="/PlayerDataDisplay"
            element={<ProtectedElement element={<PlayerDataDisplay />} />}
          />
          <Route
            path="/Tableau"
            element={<ProtectedElement element={<Tableau />} />}
          />
          <Route
            path="/Shineyapp"
            element={<ProtectedElement element={<Shineyapp />} />}
          />
          <Route
            path="/Datawrapper"
            element={<ProtectedElement element={<Datawrapper />} />}
          />
          <Route
            path="/University"
            element={<ProtectedElement element={<University />} />}
          />
          <Route
            path="/LandingPage"
            element={<ProtectedElement element={<LandingPage />} />}
          />
          <Route
            path="/AIChat"
            element={<ProtectedElement element={<AIChat />} />}
          />
          <Route
            path="/forgot-password"
            element={<ProtectedElement element={<ForgotPasswordPage />} />}
          />
          <Route
            path="/reset-password"
            element={<ProtectedElement element={<ResetPasswordPage />} />}
          />
          <Route path="*" element={<NotfoundPage />} />
        </Routes>
      </BackgroundWrapper>
    </Router>
  );
}

function ProtectedElement({ element }) {
  const { currentUser, loading } = useAuth();
  const location = useLocation();

  const isPublicPath = [
    "/login",
    "/demo",
    "/forgot-password",
    "/reset-password",
    //  "/register",
  ].includes(location.pathname);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner size="xl" />
      </div>
    );
  }

  if (isPublicPath) {
    if (currentUser) {
      return <Navigate to={location.state?.from ?? "/profile"} replace />;
    } else {
      return element;
    }
  } else {
    if (currentUser) {
      return element;
    } else {
      return (
        <Navigate
          to={{ pathname: "/login", state: { from: location.pathname } }}
          replace
        />
      );
    }
  }
}
