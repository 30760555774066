import {
  Button,
  Center,
  chakra,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../components/Card";
import { Layout } from "../components/Layout";
import { useAuth } from "../contexts/AuthContext";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const { forgotPassword } = useAuth();
  const toast = useToast();

  const [email, setEmail] = useState("");

  // Determine colors based on color mode
  const iconColor = useColorModeValue("black", "white");
  const textColor = useColorModeValue("black", "white");
  const inputBg = useColorModeValue(
    "whiteAlpha.20",
    "rgba(255, 255, 255, 0.1)"
  );

  return (
    <Layout>
      <Heading textAlign="center" my={8}>
        Reset Your Password
      </Heading>
      <Card maxW="md" mx="auto" mt={4} bg="transparent" boxShadow="none">
        <chakra.form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              await forgotPassword(email);
              toast({
                description: `An email is sent to ${email} for password reset instructions.`,
                status: "success",
                duration: 9000,
                isClosable: true,
              });
            } catch (error) {
              toast({
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            }
          }}
        >
          <Stack spacing={6} p={4}>
            <FormControl id="email">
              <FormLabel color={textColor}>
                Enter your registered email to reset the password
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color={iconColor} />
                </InputLeftElement>
                <Input
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  bg={inputBg} // Translucent background based on color mode
                  color={textColor} // Text color based on color mode
                  placeholder="Email"
                  _placeholder={{ color: textColor }} // Placeholder color
                />
              </InputGroup>
            </FormControl>
            <Button
              type="submit"
              colorScheme="blue"
              fontSize="md"
              width="100%"
              rounded="full"
            >
              Submit
            </Button>
            <Center>
              <Button
                colorScheme="blue"
                fontSize="md"
                width="100%"
                rounded="full" // Ensure the button is the same width as the Submit button
                onClick={() => navigate("/login")}
              >
                Back To Login
              </Button>
            </Center>
          </Stack>
        </chakra.form>
      </Card>
    </Layout>
  );
}
