import React, { useState } from "react";
import {
  Grid,
  GridItem,
  Heading,
  Stack,
  FormControl,
  Input,
  Card,
  FormHelperText,
  InputGroup,
  InputLeftElement,
  Button,
  chakra,
  List,
  ListItem,
  ListIcon,
  useToast,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { FaUserAlt, FaEnvelope, FaPhone, FaCheckCircle } from "react-icons/fa";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/Layout";
import { db } from "../utils/init-firebase"; // Import Firebase Firestore instance
import { addDoc, collection } from "firebase/firestore"; // Firestore functions

export default function DemoPage() {
  const navigate = useNavigate();
  const { register } = useAuth();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { colorMode } = useColorMode();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(newEmail);
    setIsEmailValid(isValid);
    setEmailHelperText(isValid ? "" : "Invalid email format");
  };

  const handleFirstNameChange = (e) => {
    const newFirstName = e.target.value;
    setFirstName(newFirstName);
    setIsFirstNameValid(newFirstName.trim() !== "");
  };

  const handleLastNameChange = (e) => {
    const newLastName = e.target.value;
    setLastName(newLastName);
    setIsLastNameValid(newLastName.trim() !== "");
  };

  const handlePhoneNumberChange = (e) => {
    const newPhoneNumber = e.target.value;
    setPhoneNumber(newPhoneNumber);

    const phonePattern = /^\+\d{1,3}\d{10}$/;
    const isValid = phonePattern.test(newPhoneNumber);
    setIsPhoneNumberValid(isValid);
    setPhoneNumberHelperText(isValid ? "" : "Invalid phone number");
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!isEmailValid) {
      toast({
        title: "Invalid Email",
        description: emailHelperText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!isFirstNameValid) {
      toast({
        title: "Invalid First Name",
        description: "First name cannot be blank.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!isLastNameValid) {
      toast({
        title: "Invalid Last Name",
        description: "Last name cannot be blank.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!isPhoneNumberValid) {
      toast({
        title: "Invalid Phone Number",
        description: phoneNumberHelperText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      // Create or update a new document in the "Demo Request" collection
      await addDoc(collection(db, "Demo Request"), {
        email,
        firstName: FirstName,
        lastName: LastName,
        phoneNumber,
        timestamp: new Date(), // Add a timestamp to track when the request was made
      });

      toast({
        title: "Demo Request Successful",
        description: "We will be contacting you shortly.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Navigate or reset form after successful submission if needed
      setEmail("");
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setPassword("");
    } catch (error) {
      toast({
        title: "Submission Failed",
        description: `An error occurred: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Layout>
      <Grid
        templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} // Responsive columns
        minHeight="84vh"
        gap={{ base: 4, md: 6 }} // Adjust gap for mobile and desktop
        p={{ base: 4, md: 8 }} // Padding for grid to ensure space around content
        alignItems="center" // Center align items vertically
      >
        {/* Left Side - Features */}
        <GridItem bg="transparent" p={4}>
          <VStack align="start" spacing={8}>
            <Heading
              fontSize={{ base: "xl", md: "2xl", lg: "3xl" }} // Responsive font sizes
            >
              Contact us to find out more about our platform!
            </Heading>
            <List spacing={6} flexGrow={1}>
              <ListItem>
                <ListIcon
                  as={FaCheckCircle}
                  color={colorMode === "dark" ? "white" : "black"}
                />
                Build customized time-saving data workflows
              </ListItem>
              <ListItem>
                <ListIcon
                  as={FaCheckCircle}
                  color={colorMode === "dark" ? "white" : "black"}
                />
                Apply AI tools to create bespoke micro-services
              </ListItem>
              <ListItem>
                <ListIcon
                  as={FaCheckCircle}
                  color={colorMode === "dark" ? "white" : "black"}
                />
                Create 24/7/365 workflow operations to maximize efficiency
              </ListItem>
              <ListItem>
                <ListIcon
                  as={FaCheckCircle}
                  color={colorMode === "dark" ? "white" : "black"}
                />
                Advanced Proprietary Encryption to protect your data.
              </ListItem>
            </List>
          </VStack>
        </GridItem>

        {/* Right Side - Registration Form */}
        <GridItem
          p={4}
          bg="transparent"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Card maxW="md" mx="auto" mt={4} bg="transparent" boxShadow="none">
            <VStack align="start" spacing={8}>
              <Heading
                fontSize={{ base: "xl", md: "2xl", lg: "3xl" }} // Responsive font sizes
              >
                Contact Us
              </Heading>
              <chakra.form onSubmit={handleFormSubmit} width="100%">
                <Stack spacing={6} mb={{ base: 16, md: 20 }}>
                  {" "}
                  {/* Add bottom margin for spacing */}
                  <FormControl id="First Name" isInvalid={!isFirstNameValid}>
                    <InputGroup size="md">
                      <InputLeftElement pointerEvents="none">
                        <FaUserAlt color="gray.300" />
                      </InputLeftElement>
                      <Input
                        name="firstName"
                        type="text"
                        required
                        value={FirstName}
                        onChange={handleFirstNameChange}
                        placeholder="First Name"
                        bg={
                          colorMode === "dark"
                            ? "rgba(255, 255, 255, 0.1)"
                            : "gray.20"
                        }
                        _placeholder={{
                          color: colorMode === "dark" ? "white" : "gray.600",
                        }}
                      />
                    </InputGroup>
                    {!isFirstNameValid && (
                      <FormHelperText color="red">
                        First name cannot be blank.
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl id="Last Name" isInvalid={!isLastNameValid}>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <FaUserAlt color="gray.300" />
                      </InputLeftElement>
                      <Input
                        name="lastName"
                        type="text"
                        required
                        value={LastName}
                        onChange={handleLastNameChange}
                        placeholder="Last Name"
                        bg={
                          colorMode === "dark"
                            ? "rgba(255, 255, 255, 0.1)"
                            : "gray.20"
                        }
                        _placeholder={{
                          color: colorMode === "dark" ? "white" : "gray.600",
                        }}
                      />
                    </InputGroup>
                    {!isLastNameValid && (
                      <FormHelperText color="red">
                        Last name cannot be blank.
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl id="email" isInvalid={!isEmailValid}>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <FaEnvelope color="gray.300" />
                      </InputLeftElement>
                      <Input
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Email"
                        bg={
                          colorMode === "dark"
                            ? "rgba(255, 255, 255, 0.1)"
                            : "gray.20"
                        }
                        _placeholder={{
                          color: colorMode === "dark" ? "white" : "gray.600",
                        }}
                      />
                    </InputGroup>
                    {!isEmailValid && (
                      <FormHelperText color="red">
                        {emailHelperText}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl id="phoneNumber" isInvalid={!isPhoneNumberValid}>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <FaPhone color="gray.300" />
                      </InputLeftElement>
                      <Input
                        name="phoneNumber"
                        type="text"
                        required
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        placeholder="Mobile Phone Number"
                        bg={
                          colorMode === "dark"
                            ? "rgba(255, 255, 255, 0.1)"
                            : "gray.20"
                        }
                        _placeholder={{
                          color: colorMode === "dark" ? "white" : "gray.600",
                        }}
                      />
                    </InputGroup>
                    {!isPhoneNumberValid && (
                      <FormHelperText color="red">
                        {phoneNumberHelperText}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    fontSize="md"
                    isLoading={isSubmitting}
                    width="100%"
                    rounded="full"
                    mt={{ base: 4, md: 6 }} // Margin for spacing
                  >
                    Submit
                  </Button>
                </Stack>
              </chakra.form>
            </VStack>
          </Card>
        </GridItem>
      </Grid>
    </Layout>
  );
}
