import React, { useState, useEffect } from "react";
import {
  VStack,
  Checkbox,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
} from "@chakra-ui/react";
import { db } from "../utils/init-firebase";
import { doc, getDocs, updateDoc, collection } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import University from "../pages/University";

function AccessControl() {
  const { currentUser } = useAuth();
  const toast = useToast();
  const [users, setUsers] = useState([]);
  const [accessControlData, setAccessControlData] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, "userProfile");
      const userSnapshot = await getDocs(usersCollection);
      const usersList = userSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersList);

      const accessData = {};
      usersList.forEach((user) => {
        accessData[user.id] = user.accessControls || {
          Per_Pay: false,
          Contract: false,
          Employee: false,
          Player: false,
          Sporting_CB: false,
          Tableau: false,
          Shiney_App: false,
          Datawrapper: false,
          University: false,
          Sporting: false,
          Business: false,
          SocioEconomic: false,
          ClientSpecific: false,
        };
      });
      setAccessControlData(accessData);
    };

    fetchUsers();
  }, []);

  const handleCheckboxChange = (userId, iconName) => {
    setAccessControlData((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        [iconName]: !prev[userId][iconName],
      },
    }));
  };

  const handleSaveChanges = async () => {
    try {
      for (const userId of Object.keys(accessControlData)) {
        const userDocRef = doc(db, "userProfile", userId);
        await updateDoc(userDocRef, {
          accessControls: accessControlData[userId],
        });
      }
      toast({
        title: "Access Controls Updated",
        description: "The access controls have been updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error Updating Access Controls",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>User</Th>
            <Th>Socio-Economic</Th>
            <Th>Sporting</Th>
            <Th>Business</Th>
            <Th>Client-Specific</Th>
            <Th>Per Pay</Th>
            <Th>Contract</Th>
            <Th>Employee</Th>
            <Th>Player</Th>
            <Th>Sporting CB</Th>
            <Th>Tableau</Th>
            <Th>Shiney App</Th>
            <Th>Datawrapper</Th>
            <Th>University</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user.id}>
              <Td>{user.displayName || user.email}</Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.SocioEconomic}
                  onChange={() =>
                    handleCheckboxChange(user.id, "SocioEconomic")
                  }
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Sporting}
                  onChange={() => handleCheckboxChange(user.id, "Sporting")}
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Business}
                  onChange={() => handleCheckboxChange(user.id, "Business")}
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.ClientSpecific}
                  onChange={() =>
                    handleCheckboxChange(user.id, "Client Specific")
                  }
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Per_Pay}
                  onChange={() => handleCheckboxChange(user.id, "Per_Pay")}
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Contract}
                  onChange={() => handleCheckboxChange(user.id, "Contract")}
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Employee}
                  onChange={() => handleCheckboxChange(user.id, "Employee")}
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Player}
                  onChange={() => handleCheckboxChange(user.id, "Player")}
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Sporting_CB}
                  onChange={() => handleCheckboxChange(user.id, "Sporting_CB")}
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Tableau}
                  onChange={() => handleCheckboxChange(user.id, "Tableau")}
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Shiney_App}
                  onChange={() => handleCheckboxChange(user.id, "Shiney_App")}
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Datawrapper}
                  onChange={() => handleCheckboxChange(user.id, "Datawrapper")}
                />
              </Td>
              <Td>
                <Checkbox
                  isChecked={accessControlData[user.id]?.Datawrapper}
                  onChange={() => handleCheckboxChange(user.id, "University")}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Button colorScheme="blue" onClick={handleSaveChanges}>
        Save Changes
      </Button>
    </VStack>
  );
}

export default AccessControl;
