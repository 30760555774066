import { useState } from "react";
import { Box, Container, Heading, Grid } from "@chakra-ui/react";
import AddContractClause from "../ContractCRUD/AddContracts";
import ContractClausesList from "../ContractCRUD/ContractList";
import { Layout } from "../components/Layout";

export default function ContractsManagment() {
  const [ContractClauseId, setContractClauseId] = useState("");

  const getContractClauseIdHandler = (id) => {
    console.log("The ID of document to be edited: ", id);
    setContractClauseId(id);
  };

  const refreshContractClauses = () => {
    // Implement the logic to refresh the book list here
    // You can force a refresh by setting a state variable or any suitable method
  };

  return (
    <Layout>
      <Heading>Contract Clause Management</Heading>
      <Container maxW="container.lg" mt={8}>
        <Grid templateColumns="repeat(1, 1fr)" gap={4}>
          <Box>
            <AddContractClause
              id={ContractClauseId}
              setContractClauseId={setContractClauseId}
              refreshContractClauses={refreshContractClauses}
            />
          </Box>
          <Box>
            <ContractClausesList
              getContractClauseId={getContractClauseIdHandler}
              refreshContractClauses={refreshContractClauses}
            />
          </Box>
        </Grid>
      </Container>
    </Layout>
  );
}
