import { initializeApp } from 'firebase/app'
import { getAuth,setPersistence, browserSessionPersistence} from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
export const functions = getFunctions(app);

const provider = new ReCaptchaV3Provider('6Lf7SIonAAAAABamIRlTjE-4Yvs63g1knosqdAx2');

setPersistence(auth, browserSessionPersistence)
  .catch((error) => {
    console.error('Error setting session persistence', error);
  });

initializeAppCheck(app, {
   provider,
   isTokenAutoRefreshEnabled: true
});

