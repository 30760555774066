import { db } from "../utils/init-firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

const ContractClausesCollectionRef = collection(db, "ContractClauses");

class ContractClausesService {
  addContractClauses = (newContractClause, currentUser) => {
    const ContractClausesWithMetadata = {
      ...newContractClause,
      createdBy: currentUser.displayName,
      dateAdded: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
    };
    return addDoc(ContractClausesCollectionRef, ContractClausesWithMetadata);
  };

  updateContractClause = (id, updatedContractClause, currentUser) => {
    const ContractClauseWithMetadata = {
      ...updatedContractClause,
      updatedBy: currentUser.displayName,
      dateUpdated: new Date().toISOString(),
    };
    const ContractClauseDoc = doc(db, "ContractClauses", id);
    return updateDoc(ContractClauseDoc, ContractClauseWithMetadata);
  };

  deleteContractClause = (id) => {
    const ContractClauseDoc = doc(db, "ContractClauses", id);
    return deleteDoc(ContractClauseDoc);
  };

  getAllContractClauses = () => {
    return getDocs(ContractClausesCollectionRef);
  };

  getContractClause = (id) => {
    const ContractClauseDoc = doc(db, "ContractClauses", id);
    return getDoc(ContractClauseDoc);
  };
}

const ContractClauseService = new ContractClausesService(); // Create an instance of ContractClause DataService

export default ContractClauseService; // Export the instance as the default export
