import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import {
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Input,
  Text,
  Button,
  ButtonGroup,
  FormControl,
  Flex,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import PlayerService from "../services/Player.services";
import { auth } from "../utils/init-firebase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddPlayer = ({ id, setPlayerId, refreshPlayers }) => {
  const [Name, setName] = useState("");
  const [Nationality, setNationality] = useState("");
  const [DOB, setDOB] = useState("");
  const [Position, setPosition] = useState("Midfielder");
  const [Club, setClub] = useState("");
  const [League, setLeague] = useState("");
  const [clubCountry, setclubCountry] = useState("");
  const [Tier, setTier] = useState("");
  const [CSD, setCSD] = useState("");
  const [CED, setCED] = useState("");
  const [age, setAge] = useState("");
  const [PlayerID, setPlayerID] = useState("");
  const [Season, setSeason] = useState("");
  const [message, setMessage] = useState({ error: false, msg: "" });
  const { currentUser } = useAuth(auth); // Get the current user from your auth context

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (Name === "" || Club === "") {
      setMessage({ error: true, msg: "All fields are mandatory!" });
      return;
    }
    const newPlayer = {
      Name,
      Nationality,
      DOB,
      Club,
      Position,
      League,
      clubCountry,
      Tier,
      CSD,
      CED,
      PlayerID,
      Season
    };

    try {
      if (id !== undefined && id !== "") {
        await PlayerService.updatePlayer(id, newPlayer, currentUser); // Pass current user
        setPlayerId("");
        setMessage({ error: false, msg: "Player Updated successfully!" });
      } else {
        await PlayerService.addPlayers(newPlayer, currentUser); // Pass current user
        setMessage({ error: false, msg: "New Player added successfully!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setName("");
    setClub("");
    setNationality("");
    setDOB(null); // Reset DatePicker for Date Of Birth
    setclubCountry("");
    setLeague("");
    setTier("");
    setCSD(null); // Reset DatePicker for Contract Start Date
    setCED(null); // R
    setAge("");
    setPlayerID("");
    setSeason("");
    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  // Function to calculate age
  const calculateAge = (dob) => {
    if (!dob) {
      return "";
    }

    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  // Update age when DOB changes
  const handleDOBChange = (date) => {
    setDOB(date);

    if (date) {
      setAge(calculateAge(date)); // Calculate age only if date is not null
    } else {
      setAge(""); // Clear the age if DOB is cleared
    }
  };

  useEffect(() => {

    const editHandler = async () => {
      setMessage("");
      try {
        const docSnap = await PlayerService.getPlayer(id);

        // Convert Firestore Timestamps to JavaScript Date objects
        const dobDate = docSnap.data().DOB.toDate();
        const csdDate = docSnap.data().CSD.toDate();
        const cedDate = docSnap.data().CED.toDate();

        setName(docSnap.data().Name);
        setClub(docSnap.data().Club);
        setNationality(docSnap.data().Nationality);
        setDOB(dobDate);
        setclubCountry(docSnap.data().clubCountry);
        setLeague(docSnap.data().League);
        setTier(docSnap.data().Tier);
        setCSD(csdDate);
        setCED(cedDate);
        setPosition(docSnap.data().Position);
        setPlayerID(docSnap.data().PlayerID);
        setSeason(docSnap.data().Season);
      } catch (err) {
        setMessage({ error: true, msg: err.message });
      }
    };

    if (id !== undefined && id !== "") {
      editHandler();
    }
  }, [id]);

  return (
    <Box p={4} className="box">
      {message?.msg && (
        <Alert
          status={message?.error ? "error" : "success"}
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          rounded="md"
          mb={4}
          onClose={() => setMessage({ msg: "", error: false })}
        >
          <AlertIcon />
          <AlertTitle mt={4} mb={1}>
            {message?.msg}
          </AlertTitle>
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <FormControl id="formPlayerName" mb={3}>
          <InputGroup>
            <InputLeftAddon children="A" />
            <Input
              type="text"
              placeholder="Player Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl mb={3}>
          <ButtonGroup spacing={4}>
            <Button
              variant={Position === "Midfielder" ? "solid" : "outline"}
              colorScheme="green"
              onClick={() => {
                setPosition("Midfielder");
              }}
            >
              Midfielder
            </Button>
            <Button
              variant={Position === "Defender" ? "solid" : "outline"}
              colorScheme="red"
              onClick={() => {
                setPosition("Defender");
              }}
            >
              Defender
            </Button>
            <Button
              variant={Position === "Attacker" ? "solid" : "outline"}
              colorScheme="orange"
              onClick={() => {
                setPosition("Attacker");
              }}
            >
              Attacker
            </Button>
            <Button
              variant={Position === "Goal Keeper" ? "solid" : "outline"}
              colorScheme="cyan"
              onClick={() => {
                setPosition("Goal Keeper");
              }}
            >
              Goal Keeper
            </Button>
          </ButtonGroup>
        </FormControl>

        <Flex alignItems="center" mb={3} justifyContent="flex-start">
          <FormControl id="formPlayerDOB" mr={3}>
            <InputGroup>
              <InputLeftAddon children="B" />
              <DatePicker
                selected={DOB}
                onChange={handleDOBChange}
                dateFormat="MM/dd/yyyy"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                placeholderText="Date Of Birth"
                isClearable
                customInput={<Input />}
                maxDate={new Date()} // Restrict future dates
              />
            </InputGroup>
          </FormControl>

          {/* Display age */}
          <Box pl={3} border="1px" borderColor="gray.200" borderRadius="md">
            <Text fontSize="md">Age: {age}</Text>
          </Box>
        </Flex>

        <FormControl id="formPlayerNationality" mb={3}>
          <InputGroup>
            <InputLeftAddon children="C" />
            <Input
              type="text"
              placeholder="Nationality"
              value={Nationality}
              onChange={(e) => setNationality(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl id="formPlayerClub" mb={3}>
          <InputGroup>
            <InputLeftAddon children="D" />
            <Input
              type="text"
              placeholder="Player Club"
              value={Club}
              onChange={(e) => setClub(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl id="formPlayerClubCountry" mb={3}>
          <InputGroup>
            <InputLeftAddon children="E" />
            <Input
              type="text"
              placeholder="Club Country"
              value={clubCountry}
              onChange={(e) => setclubCountry(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl id="formPlayerLeague" mb={3}>
          <InputGroup>
            <InputLeftAddon children="F" />
            <Input
              type="text"
              placeholder="League"
              value={League}
              onChange={(e) => setLeague(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl id="formPlayerTier" mb={3}>
          <InputGroup>
            <InputLeftAddon children="G" />
            <Input
              type="text"
              placeholder="Tier"
              value={Tier}
              onChange={(e) => setTier(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        <Flex direction="row" justifyContent="space-between" mb={3}>
          <FormControl id="formPlayerCSD" mr={3}>
            <InputGroup>
              <InputLeftAddon children="H" />
              <DatePicker
                selected={CSD}
                onChange={(date) => setCSD(date)}
                dateFormat="MM/dd/yyyy"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                placeholderText="Contract Start Date"
                isClearable
                customInput={<Input />}
                maxDate={new Date()} // Restrict future dates
              />
            </InputGroup>
          </FormControl>

          <FormControl id="formPlayerCED" mr={3}>
            <InputGroup>
              <InputLeftAddon children="I" />
              <DatePicker
                selected={CED}
                onChange={(date) => setCED(date)}
                dateFormat="MM/dd/yyyy"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                placeholderText="Contract End Date"
                isClearable
                customInput={<Input />}
              />
            </InputGroup>
          </FormControl>

          <FormControl id="formPlayerID" mb={3}>
          <InputGroup>
            <InputLeftAddon children="I" />
            <Input
              type="text"
              placeholder="PlayerID"
              value={PlayerID}
              onChange={(e) => setPlayerID(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl id="formPlayerSeason" mb={3}>
          <InputGroup>
            <InputLeftAddon children="J" />
            <Input
              type="text"
              placeholder="Season (Start Year)"
              value={Season}
              onChange={(e) => setSeason(e.target.value)}
            />
          </InputGroup>
        </FormControl>
        </Flex>

        <FormControl>
          <Button type="submit" colorScheme="blue">
            Add/Update
          </Button>
        </FormControl>
      </form>
    </Box>
  );
};

export default AddPlayer;
