import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Container,
  Heading,
  Switch,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Progress,
  Text,
  Button,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  InputGroup,
  InputRightElement,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { Layout } from "../components/Layout";
import {
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../utils/init-firebase";
import { useAuth } from "../contexts/AuthContext";
import { updateProfile } from "firebase/auth";
import ManageUserRoles from "../components/ManageUserRoles";
import AccessControl from "../components/AccessControl";

export default function ProfilePage() {
  const { currentUser, deleteUser, logout, forgotPassword } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    displayName: "",
    firstName: "",
    lastName: "",
    email: "",
    isEmailVerified: false,
    phoneNumber: "",
    isPhoneNumberVerified: false,
    address: "",
    is2FAEnabled: false,
    role: "AppUser",
  });
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [initialFormData, setInitialFormData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPassword, setShowPassword] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const docRef = doc(db, "userProfile", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const initialData = {
            displayName: data.displayName || currentUser.displayName || "",
            firstName:
              data.firstName ||
              (currentUser.displayName
                ? currentUser.displayName.split(" ")[0]
                : ""),
            lastName:
              data.lastName ||
              (currentUser.displayName
                ? currentUser.displayName.split(" ").slice(1).join(" ")
                : ""),
            email: data.email || currentUser.email || "",
            isEmailVerified: data.isEmailVerified || currentUser.emailVerified,
            phoneNumber: data.phoneNumber || currentUser.phoneNumber || "",
            isPhoneNumberVerified:
              data.phoneNumber || currentUser.phoneNumber
                ? true
                : data.isPhoneNumberVerified || false,
            address: data.address || "",
            is2FAEnabled: data.is2FAEnabled || false,
            role: data.role || "AppUser",
          };
          setFormData(initialData);
          setInitialFormData(initialData);
        } else {
          const initialData = {
            displayName: currentUser.displayName || "",
            firstName: currentUser.displayName
              ? currentUser.displayName.split(" ")[0]
              : "",
            lastName: currentUser.displayName
              ? currentUser.displayName.split(" ").slice(1).join(" ")
              : "",
            email: currentUser.email || "",
            isEmailVerified: currentUser.emailVerified,
            phoneNumber: currentUser.phoneNumber || "",
            isPhoneNumberVerified: currentUser.phoneNumber ? true : false,
            address: "",
            is2FAEnabled: false,
            role: "AppUser",
          };
          setFormData(initialData);
          setInitialFormData(initialData);
        }
      } catch (error) {
        toast({
          title: "Error fetching profile",
          description: `An error occurred: ${error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchUserProfile();
  }, [currentUser, toast]);

  const handleCancel = () => {
    setFormData(initialFormData);
    setIsFormDirty(false);
  };

  useEffect(() => {
    setCompletionPercentage(calculateCompletionPercentage(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      if (prev[name] !== value) {
        setIsFormDirty(true);
      }
      return { ...prev, [name]: value };
    });
  };

  const handleSaveChanges = async () => {
    try {
      const updatedDisplayName = `${formData.firstName} ${formData.lastName}`;
      const compPercent = calculateCompletionPercentage(formData);
      const dataToSave = {
        ...formData,
        displayName: updatedDisplayName,
        completionPercentage: compPercent,
        dateUpdated: serverTimestamp(),
      };

      await setDoc(doc(db, "userProfile", currentUser.uid), dataToSave);
      await updateProfile(currentUser, { displayName: updatedDisplayName });

      toast({
        title: "Profile Updated",
        description: "Your profile has been updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setIsFormDirty(false);
    } catch (error) {
      toast({
        title: "Error updating profile",
        description: `An error occurred: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleVerificationToggle = (e) => {
    const { name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handle2FAToggle = () => {
    setFormData((prev) => ({
      ...prev,
      is2FAEnabled: !prev.is2FAEnabled,
    }));
    setIsFormDirty(true);
  };

  function calculateCompletionPercentage(data) {
    const fields = ["firstName", "lastName", "email", "phoneNumber", "address"];
    const filledFields = fields.filter((field) => Boolean(data[field])).length;
    return (filledFields / fields.length) * 100;
  }

  const handleDeleteAndLogout = async () => {
    try {
      const docRef = doc(db, "userProfile", currentUser.uid);
      await deleteDoc(docRef);
      await deleteUser(currentUser);

      toast({
        title: "Account deleted.",
        description: "Your account has been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      await new Promise((resolve) => setTimeout(resolve, 3000));
      logout();
    } catch (error) {
      toast({
        title: "Error deleting account.",
        description: `An error occurred while deleting your account: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleHomepageClick = () => {
    navigate("/LandingPage");
  };

  const initiatePasswordReset = async () => {
    if (!currentUser?.email) {
      toast({
        title: "Error",
        description: "No email address found for password reset",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsResetting(true);
      await forgotPassword(currentUser.email);
      onOpen();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <Layout>
      <Container maxW="container.lg" overflowX="auto" py={4}>
        <Tabs variant="enclosed">
          <TabList mb="1em">
            <Tab>Profile</Tab>
            {formData.role === "Admin" && <Tab>Admin Panel</Tab>}
            {formData.role === "Admin" && <Tab>Access Control</Tab>}
            <Tab>Payment Information</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Heading mb={4}>Account Information</Heading>
              <Progress
                value={completionPercentage}
                colorScheme="green"
                size="sm"
                mb={4}
              />
              <Text mb={4} fontWeight="bold" textAlign="center" width="100%">
                {completionPercentage.toFixed(0)}% Complete
              </Text>
              <VStack spacing={6} align="stretch">
                {/* First Name */}
                <FormControl>
                  <FormLabel mb={0} fontSize="md" fontWeight="bold">
                    Account Details
                  </FormLabel>
                  {/* Divider */}
                  <Box borderBottom="2px" borderColor="gray.200" my={6} />
                  <Flex align="center">
                    <FormLabel minW="130px" mb={0}>
                      First Name
                    </FormLabel>
                    <Input
                      type="text"
                      value={formData.firstName}
                      name="firstName"
                      onChange={handleInputChange}
                      variant="flushed"
                    />
                  </Flex>
                </FormControl>

                {/* Last Name */}
                <FormControl>
                  <Flex align="center">
                    <FormLabel minW="130px" mb={0}>
                      Last Name
                    </FormLabel>
                    <Input
                      type="text"
                      value={formData.lastName}
                      name="lastName"
                      onChange={handleInputChange}
                      variant="flushed"
                    />
                  </Flex>
                </FormControl>

                {/* Email */}
                <FormControl>
                  <Flex align="center">
                    <FormLabel minW="130px" mb={0}>
                      Email ID
                    </FormLabel>
                    <Input
                      type="email"
                      value={formData.email}
                      name="email"
                      onChange={handleInputChange}
                      isReadOnly={true}
                      variant="flushed"
                    />
                    <Box ml={4} display="flex" alignItems="center">
                      <Text fontSize="sm" mr={2}>
                        Verified
                      </Text>
                      <Switch
                        isChecked={formData.isEmailVerified}
                        name="isEmailVerified"
                        onChange={handleVerificationToggle}
                        isReadOnly={true}
                      />
                    </Box>
                  </Flex>
                </FormControl>

                {/* Password */}
                <FormControl>
                  <Flex align="center">
                    <FormLabel minW="130px" mb={0}>
                      Password
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type="password"
                        value="********"
                        isReadOnly
                        variant="flushed"
                        pr="4.5rem"
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          variant="link"
                          onClick={initiatePasswordReset}
                          size="sm"
                          isLoading={isResetting}
                          loadingText="Sending..."
                          colorScheme="blue"
                          textDecoration="underline"
                          fontWeight="normal"
                          _hover={{
                            textDecoration: "underline",
                            opacity: 0.8,
                          }}
                        >
                          Reset Password
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </Flex>
                </FormControl>

                {/* Phone */}
                <FormControl>
                  <Flex align="center">
                    <FormLabel minW="130px" mb={0}>
                      Phone Number
                    </FormLabel>
                    <Input
                      type="tel"
                      value={formData.phoneNumber}
                      name="phoneNumber"
                      onChange={handleInputChange}
                      isReadOnly={true}
                      variant="flushed"
                    />
                    <Box ml={4} display="flex" alignItems="center">
                      <Text fontSize="sm" mr={2}>
                        Verified
                      </Text>
                      <Switch
                        isChecked={formData.isPhoneNumberVerified}
                        name="isPhoneNumberVerified"
                        onChange={handleVerificationToggle}
                        isReadOnly={true}
                      />
                    </Box>
                  </Flex>
                </FormControl>

                {/* Address */}
                <FormControl>
                  <Flex align="center">
                    <FormLabel minW="130px" mb={0}>
                      Address
                    </FormLabel>
                    <Input
                      type="text"
                      value={formData.address}
                      name="address"
                      onChange={handleInputChange}
                      variant="flushed"
                    />
                  </Flex>
                </FormControl>

                {/* 2FA */}
                <FormControl>
                  <Flex justify="space-between" align="center">
                    <FormLabel mb={0}>
                      Two-Factor Authentication (2FA)
                    </FormLabel>
                    <Switch
                      isChecked={formData.is2FAEnabled}
                      onChange={handle2FAToggle}
                      name="is2FAEnabled"
                      isDisabled={currentUser?.role !== "Admin"}
                    />
                  </Flex>
                </FormControl>

                {/* Action Buttons */}
                {isFormDirty && (
                  <Flex gap={4} mt={4}>
                    <Button
                      colorScheme="green"
                      rounded="full"
                      onClick={handleSaveChanges}
                      size="lg"
                      flex="1"
                    >
                      Save Changes
                    </Button>
                    <Button
                      colorScheme="red"
                      rounded="full"
                      onClick={handleCancel}
                      size="lg"
                      flex="1"
                    >
                      Cancel
                    </Button>
                  </Flex>
                )}

                {/* Divider */}
                <Box borderBottom="2px" borderColor="gray.200" my={6} />

                <FormControl>
                  <Flex justify="space-between" align="center">
                    <FormLabel mb={0} fontSize="md" fontWeight="bold">
                      Subscription Plan
                    </FormLabel>
                    <Box>
                      <Flex align="center" gap={4}>
                        <Text color="green.500" fontWeight="semibold">
                          Active
                        </Text>
                        <Button
                          variant="outline"
                          colorScheme="blue"
                          size="sm"
                          onClick={() => {
                            /* handle subscription change */
                          }}
                        >
                          Change Plan
                        </Button>
                      </Flex>
                    </Box>
                  </Flex>
                  <Text mt={2} color="gray.600" fontSize="sm">
                    Premium Plan - $9.99/month
                  </Text>
                  <Text color="gray.500" fontSize="sm">
                    Next billing date: {new Date().toLocaleDateString()}
                  </Text>
                </FormControl>

                {/* Divider */}
                <Box borderBottom="2px" borderColor="gray.200" my={6} />

                {/* Footer Buttons */}
                <Flex
                  gap={4}
                  direction={{ base: "column", md: "row" }}
                  mt={4}
                  justify="center"
                >
                  {formData.role === "Admin" && (
                    <Button
                      colorScheme="red"
                      rounded="full"
                      onClick={handleDeleteAndLogout}
                      size="lg"
                      flex="1"
                    >
                      Delete Account and Logout
                    </Button>
                  )}
                  <Button
                    colorScheme="blue"
                    rounded="full"
                    onClick={handleHomepageClick}
                    size="lg"
                    flex="1"
                  >
                    Homepage / Landing Page
                  </Button>
                </Flex>
              </VStack>

              {/* Password Reset Modal */}
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Reset Password</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <VStack spacing={4}>
                      <Alert status="info">
                        <AlertIcon />
                        <Text>
                          A password reset link has been sent to your email.
                          Please click the link in your email to reset your
                          password.
                        </Text>
                      </Alert>
                      <Text fontSize="sm" color="gray.600">
                        You can close this window. The reset link in your email
                        will take you to the password reset page.
                      </Text>
                    </VStack>
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </TabPanel>

            {/* Admin Panel Tab */}
            {formData.role === "Admin" && (
              <TabPanel>
                <Heading mb={4}>Admin Panel</Heading>
                <ManageUserRoles />
              </TabPanel>
            )}

            {/* Access Control Tab */}
            {formData.role === "Admin" && (
              <TabPanel>
                <Heading mb={4}>Access Control</Heading>
                <AccessControl />
              </TabPanel>
            )}

            {/* Payment Information Tab */}
            <TabPanel>
              <Heading mb={4}>Payment Information</Heading>
              <Text>This is where users can manage their payment methods.</Text>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Layout>
  );
}

// Create a separate route component for handling the password reset
export function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const toast = useToast();
  const [isResetting, setIsResetting] = useState(false);
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  // Get the oobCode from URL parameters
  const oobCode = searchParams.get("oobCode");

  useEffect(() => {
    if (!oobCode) {
      toast({
        title: "Invalid Link",
        description: "This password reset link is invalid or has expired.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    }
  }, [oobCode, navigate, toast]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    // Validate passwords
    if (!passwords.newPassword || !passwords.confirmPassword) {
      toast({
        title: "Error",
        description: "Please fill in all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (passwords.newPassword !== passwords.confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsResetting(true);
      await resetPassword(oobCode, passwords.newPassword);
      toast({
        title: "Success",
        description: "Your password has been reset successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <Container maxW="container.sm" py={8}>
      <VStack spacing={6}>
        <Heading size="lg">Reset Password</Heading>
        <Alert status="info">
          <AlertIcon />
          <Text>Enter your new password below</Text>
        </Alert>

        <form onSubmit={handlePasswordReset} style={{ width: "100%" }}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>New Password</FormLabel>
              <InputGroup>
                <Input
                  name="newPassword"
                  type={showPassword ? "text" : "password"}
                  value={passwords.newPassword}
                  onChange={handleInputChange}
                  placeholder="Enter new password"
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Confirm Password</FormLabel>
              <Input
                name="confirmPassword"
                type="password"
                value={passwords.confirmPassword}
                onChange={handleInputChange}
                placeholder="Confirm new password"
              />
            </FormControl>

            <Button
              type="submit"
              colorScheme="blue"
              isLoading={isResetting}
              loadingText="Resetting..."
              width="100%"
            >
              Reset Password
            </Button>
          </VStack>
        </form>
      </VStack>
    </Container>
  );
}
