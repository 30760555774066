import { useState } from "react";
import { Box, Container, Heading, Grid } from "@chakra-ui/react";
import AddPlayer from "../PlayerCRUD/AddPlayers";
import PlayersList from "../PlayerCRUD/PlayersList";
import { Layout } from "../components/Layout";

export default function PlayerManagment() {
  const [playerId, setPlayerId] = useState("");

  const getPlayerIdHandler = (id) => {
    setPlayerId(id);
  };

  const refreshPlayers = () => {
    // Implement the logic to refresh the book list here
    // You can force a refresh by setting a state variable or any suitable method
  };

  return (
    <Layout>
      <Heading>Player Management</Heading>
      <Container maxW="container.lg" mt={8}>
        <Grid templateColumns="repeat(1, 1fr)" gap={4}>
          <Box>
            <AddPlayer
              id={playerId}
              setPlayerId={setPlayerId}
              refreshBooks={refreshPlayers}
            />
          </Box>
          <Box>
            <PlayersList
              getPlayerId={getPlayerIdHandler}
              refreshBooks={refreshPlayers}
            />
          </Box>
        </Grid>
      </Container>
    </Layout>
  );
}
