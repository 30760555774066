import { Heading, Center, Box, Text, Spinner } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

export default function NotfoundPage() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Setting a 2 seconds delay before removing the spinner
  }, []);

  return (
    <Center h="80vh" flexDirection="column">
      {loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="red.500"
          size="xl"
        />
      ) : (
        <Box
          boxShadow="lg"
          p={5}
          rounded="md"
          bg="gray.100"
          textAlign="center"
          w={["95%", "85%", "70%", "50%"]}
        >
          <Heading mb={4} color="red.500" size="xl">
            404 - Page Not Found
          </Heading>
          <Text fontSize="lg" color="gray.700">
            Sorry, the page you are looking for does not exist.
          </Text>
        </Box>
      )}
    </Center>
  );
}
