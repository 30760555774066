import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Tabs,
  TabList,
  Tab,
  Text,
  Spinner,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Divider,
  Tr,
  Th,
  Td,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { auth } from "../utils/init-firebase";
import PlayerService from "../services/Player.services";
import { useContractClauses } from "../ContractCRUD/ContractList";
import { Layout } from "../components/Layout";

const PlayersPage = () => {
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const contractClauses = useContractClauses();
  const [rows, setRows] = useState({});

  const changeSelectedPlayer = (player) => {
    setRows((prevState) => ({
      ...prevState,
      [selectedPlayer?.id]: rows[selectedPlayer?.id] || [],
    }));

    setSelectedPlayer(player);

    if (!rows[player.id]) {
      setRows((prevState) => ({
        ...prevState,
        [player.id]: [
          {
            clause: contractClauses[0] || "",
            yesNo: "",
            performanceData: "",
            overrideValue: "",
            financialValue: "",
            result: "",
            id: 1,
          },
        ],
      }));
    }
  };
  useEffect(() => {
    const clearTimer = setTimeout(() => {
      localStorage.removeItem("rows");
      // or to clear all
      // localStorage.clear();
    }, 300000); // 300000ms equals to 5 minutes

    return () => clearTimeout(clearTimer); // Clear the timer if the component is unmounted before 5 minutes
  }, []);

  useEffect(() => {
    const fetchPlayers = async () => {
      // Fetching logic...
      // Assuming you set the fetched players in state and select the first player as default
      setSelectedPlayer(selectedPlayer);
    };
    fetchPlayers();
  }, []);

  // Effect hook to decrypt and load data for the selected player
  useEffect(() => {
    if (selectedPlayer) {
      handleDecryptAndLoad();
    }
  }, [selectedPlayer]); // Depend on selectedPlayer

  useEffect(() => {
    localStorage.setItem("rows", JSON.stringify(rows));
  }, [rows]);

  useEffect(() => {
    // Assuming fetchPlayers fetches the players data and updates the state
    const fetchPlayers = async () => {
      try {
        setIsLoading(true);
        const data = await PlayerService.getAllPlayers();
        const playersData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setPlayers(playersData);
        if (playersData.length > 0) {
          setSelectedPlayer(playersData[0]);
        }
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchPlayers();
  }, []); // This will run only once when the component mounts

  useEffect(() => {
    const fetchPlayerGoalsData = async (playerId, season) => {
      try {
        const userToken = await auth.currentUser.getIdToken();
        const response = await fetch(
          `https://api-zlbmixsola-uc.a.run.app/api/playerGoals?id=${playerId}&season=${season}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "Error fetching goals data");
        }

        console.log("Fetched goals data:", data.goals); // Debugging log
        return data.goals;
      } catch (error) {
        console.error("Error:", error);
        return null;
      }
    };

    const loadGoalsData = async () => {
      if (selectedPlayer && selectedPlayer.PlayerID && selectedPlayer.Season) {
        try {
          const goals = await fetchPlayerGoalsData(
            selectedPlayer.PlayerID,
            selectedPlayer.Season
          );
          console.log("Fetched goals:", goals); // Debugging log

          setRows((currentRows) => {
            console.log("Current rows before update:", currentRows); // Log current rows

            const updatedRows = { ...currentRows };
            if (updatedRows[selectedPlayer.id]) {
              updatedRows[selectedPlayer.id] = updatedRows[
                selectedPlayer.id
              ].map((row) => {
                console.log("Row clause:", row.clause); // Log each row's clause

                if (
                  row.clause.ContractClause === "Goals - Number" &&
                  row.clause.Competition === "Domestic"
                ) {
                  console.log(`Updating goals for row:`, row); // Log before updating
                  return { ...row, performanceData: goals };
                }

                return row;
              });
            } else {
              console.log(`No rows found for player ID: ${selectedPlayer.id}`);
            }

            console.log("Updated rows after update:", updatedRows); // Log after update
            return updatedRows;
          });
        } catch (error) {
          console.error("Error fetching player goals data:", error);
        }
      }
    };

    loadGoalsData();
  }, [selectedPlayer]);

  const fetchPlayerGoalsData = async (playerId, season) => {
    try {
      const userToken = await auth.currentUser.getIdToken();
      const response = await fetch(
        `https://api-zlbmixsola-uc.a.run.app/api/playerGoals?id=${playerId}&season=${season}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Error fetching goals data");
      }

      console.log("Fetched goals data:", data.goals); // Debugging log
      return data.goals;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const loadGoalsData = async () => {
    if (selectedPlayer && selectedPlayer.PlayerID && selectedPlayer.Season) {
      try {
        const goals = await fetchPlayerGoalsData(
          selectedPlayer.PlayerID,
          selectedPlayer.Season
        );
        console.log("Fetched goals:", goals); // Debugging log

        setRows((currentRows) => {
          console.log("Current rows before update:", currentRows); // Log current rows

          const updatedRows = { ...currentRows };
          if (updatedRows[selectedPlayer.id]) {
            updatedRows[selectedPlayer.id] = updatedRows[selectedPlayer.id].map(
              (row) => {
                console.log("Row clause:", row.clause); // Log each row's clause

                if (row.clause.ContractClause === "Goals - Number") {
                  console.log(`Updating goals for row:`, row); // Log before updating
                  return { ...row, performanceData: goals };
                }

                return row;
              }
            );
          } else {
            console.log(`No rows found for player ID: ${selectedPlayer.id}`);
          }

          console.log("Updated rows after update:", updatedRows); // Log after update
          return updatedRows;
        });
      } catch (error) {
        console.error("Error fetching player goals data:", error);
      }
    }
  };

  const handleEncryptAndSave = async () => {
    try {
      if (!auth.currentUser) {
        console.error("User is not authenticated");
        return;
      }

      const userToken = await auth.currentUser.getIdToken();

      const dataToEncrypt = {
        data: rows[selectedPlayer.id],
        token: userToken,
        userName: auth.currentUser.displayName,
        playerName: selectedPlayer.Name,
        playerClub: selectedPlayer.Club,
        playerPosition: selectedPlayer.Position,
      };

      const response = await fetch(
        "https://encryptandsave-zlbmixsola-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToEncrypt),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        console.log("Data encrypted and saved successfully!");

        // Optionally, provide feedback based on whether a new record was created or an existing one was updated
        if (responseData.updated) {
          console.log("Existing record updated!");
        } else {
          console.log("New record created!");
        }
        window.location.reload();
        setRows((prevState) => {
          const newRows = { ...prevState };
          delete newRows[selectedPlayer.id];
          return newRows;
        });
      } else {
        console.error("Failed to encrypt and save data.");
      }
    } catch (error) {
      console.error("Error encrypting and saving data:", error);
    }
  };

  const handleDecryptAndLoad = async () => {
    try {
      const userToken = await auth.currentUser.getIdToken();
      const response = await fetch(
        "https://decryptandload-zlbmixsola-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: userToken,
            playerName: selectedPlayer.Name,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        if (responseData.data && responseData.data.length > 0) {
          setRows((prevState) => ({
            ...prevState,
            [selectedPlayer.id]: responseData.data,
          }));
        } else {
          console.log("No data found for the selected player.");
          // Optionally, provide an option for the user to create a new record
        }
      } else {
        console.error("Failed to decrypt and load data.");
      }
    } catch (error) {
      console.error("Error decrypting and loading data:", error);
    }
  };

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        setIsLoading(true);
        const data = await PlayerService.getAllPlayers();
        const playersData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        console.log("Player data is:", playersData);

        setPlayers(playersData);
        setSelectedPlayer(playersData[0]);

        const savedRows = JSON.parse(localStorage.getItem("rows")) || {};

        // Initialize rows state with a default row for the first player
        // if not already available in localStorage
        if (!savedRows[playersData[0].id]) {
          setRows({
            ...savedRows,
            [playersData[0].id]: [
              {
                clause: contractClauses[0] || "",
                yesNo: "",
                performanceData: "",
                overrideValue: "",
                financialValue: "",
                result: "",
                id: 1,
              },
            ],
          });
        } else {
          setRows(savedRows);
        }

        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchPlayers();
  }, [contractClauses]);

  const DynamicTable = ({ contractClauses, rows, setRows }) => {
    const addRow = () => {
      setRows((prevState) => {
        const currentRows = prevState[selectedPlayer.id] || [];
        return {
          ...prevState,
          [selectedPlayer.id]: [
            ...currentRows,
            {
              clause: contractClauses[currentRows.length] || "",
              yesNo: "",
              performanceData: "",
              overrideValue: "",
              financialValue: "",
              result: "",
              id: currentRows.length + 1,
            },
          ],
        };
      });
    };

    const deleteRow = (id) => {
      setRows((prevState) => {
        const currentRows = prevState[selectedPlayer.id] || [];
        const updatedRows = currentRows.filter((row) => row.id !== id);
        return {
          ...prevState,
          [selectedPlayer.id]: updatedRows,
        };
      });
    };

    const updateRow = (id, field, value) => {
      setRows((prevState) => {
        const currentRows = [...prevState[selectedPlayer.id]];
        const rowIndex = currentRows.findIndex((row) => row.id === id);

        if (rowIndex === -1) return prevState;

        const updatedRow = {
          ...currentRows[rowIndex],
          [field]: value,
        };

        if (
          field === "performanceData" ||
          field === "overrideValue" ||
          field === "financialValue"
        ) {
          updatedRow.result =
            (updatedRow.overrideValue > 0
              ? updatedRow.overrideValue
              : updatedRow.performanceData) * updatedRow.financialValue;
        }

        currentRows[rowIndex] = updatedRow;

        return {
          ...prevState,
          [selectedPlayer.id]: currentRows,
        };
      });
    };

    return (
      <Box boxShadow="sm" p="5" borderRadius="md" maxW="full">
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th>Contract Clauses</Th>
              <Th>Yes/No</Th>
              <Th>Sporting Criteria</Th>
              <Th>Override Value</Th>
              <Th>Financial Value</Th>
              <Th>Result</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rows.map((row, index) => (
              <Tr key={index}>
                <Td
                  whiteSpace="normal"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  <select
                    value={row.clause} // Added to bind the value
                    onChange={(e) =>
                      updateRow(row.id, "clause", e.target.value)
                    }
                  >
                    {contractClauses.map((clause, index) => (
                      <option key={index} value={clause.ContractClause}>
                        {clause.ContractClause}
                      </option>
                    ))}
                  </select>
                </Td>
                <Td>
                  <select
                    value={row.yesNo}
                    onChange={(e) => updateRow(row.id, "yesNo", e.target.value)}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </Td>
                <Td padding="2">
                  <Input
                    type="number"
                    value={row.performanceData}
                    onChange={(e) => {
                      const value = Number(e.target.value);

                      if (isNaN(value)) {
                        console.error("Invalid number");
                        return;
                      }

                      updateRow(row.id, "performanceData", value);
                    }}
                    placeholder="Value"
                    width="100px" // Setting a fixed width
                  />
                </Td>
                <Td padding="2">
                  <Input
                    type="number"
                    value={row.overrideValue}
                    onChange={(e) =>
                      updateRow(
                        row.id,
                        "overrideValue",
                        parseFloat(e.target.value)
                      )
                    }
                    placeholder="Value"
                    width="100px" // Setting a fixed width
                  />
                </Td>
                <Td padding="2">
                  <Input
                    type="number"
                    value={row.financialValue}
                    onChange={(e) =>
                      updateRow(row.id, "financialValue", e.target.value)
                    }
                    placeholder="Value"
                    width="100px" // Setting a fixed width
                  />
                </Td>
                <Td>{row.result}</Td>
                <Td>
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => deleteRow(row.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Button onClick={addRow} mt={4}>
          Add Row
        </Button>{" "}
      </Box>
    );
  };

  const maxWidth = useMemo(() => {
    return (
      players.reduce((max, player) => Math.max(max, player.Name.length), 0) *
        8 +
      "px"
    );
  }, [players]);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }
  return (
    <Layout>
      <Box display="flex" flexDirection="row" p={4}>
        <Tabs variant="enclosed" maxW="sm" mr={4}>
          <TabList
            display="block"
            borderRight="1px solid"
            borderColor="gray.200"
            pr={4}
            width="max-content"
          >
            {players.map((player, index) => (
              <Tab
                key={player.id}
                onClick={() => changeSelectedPlayer(player)}
                _selected={{
                  bg: "gray.200",
                  color: "blue.500",
                  borderBottom: "none",
                }}
                width="100%"
                borderRadius="md"
                style={{ minWidth: maxWidth }}
              >
                <Text isTruncated maxWidth="100%">
                  {player.Name}
                </Text>
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Divider orientation="vertical" height="100%" alignSelf="center" />

        <Box flex="1">
          <Box mb={4}>
            {selectedPlayer ? (
              <Box>
                <Text>
                  <strong>Name:</strong> {selectedPlayer.Name}
                </Text>
                <Text>
                  <strong>Club:</strong> {selectedPlayer.Club}
                </Text>
                <Text>
                  <strong>Position:</strong> {selectedPlayer.Position}
                </Text>
              </Box>
            ) : (
              <Text>Select a player to view details</Text>
            )}
          </Box>

          {selectedPlayer && (
            <Box p={4} borderRadius="md" boxShadow="sm">
              <DynamicTable
                contractClauses={contractClauses}
                rows={rows[selectedPlayer.id] || []}
                setRows={setRows}
              />
              <Box marginTop="4" display="flex" justifyContent="space-between">
                <Button onClick={handleEncryptAndSave} colorScheme="green">
                  Encrypt and Save
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default PlayersPage;
