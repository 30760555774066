import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Select,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { db } from "../utils/init-firebase";
import {
  doc,
  getDoc, // Make sure to import getDoc instead of using it directly
  getDocs,
  updateDoc,
  collection,
  query,
  where,
} from "firebase/firestore";

function ManageUserRoles() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedRole, setSelectedRole] = useState("AppUser");
  const toast = useToast();

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, "userProfile");
      const userSnapshot = await getDocs(usersCollection);
      const usersList = userSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersList);
    };

    fetchUsers();
  }, []);

  const handleRoleChange = async () => {
    if (selectedUser && selectedRole) {
      try {
        const userDocRef = doc(db, "userProfile", selectedUser);
        const userDocSnapshot = await getDoc(userDocRef);

        if (!userDocSnapshot.exists()) {
          toast({
            title: "User Not Found",
            description: "The selected user does not exist.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        const currentRole = userDocSnapshot.data().role;

        if (currentRole === "Admin" && selectedRole !== "Admin") {
          const adminQuery = query(
            collection(db, "userProfile"),
            where("role", "==", "Admin")
          );
          const adminSnapshot = await getDocs(adminQuery);
          const adminCount = adminSnapshot.size;

          if (adminCount <= 1) {
            toast({
              title: "Action Denied",
              description: "There must be at least one admin.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            return;
          }
        }

        // Proceed to update the role if the check passes
        await updateDoc(userDocRef, {
          role: selectedRole,
        });

        console.log(
          `Role for user ${userDocRef.id} updated to ${selectedRole}`
        );

        toast({
          title: "Role Updated",
          description: `User role updated to ${selectedRole}.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error updating role:", error);
        toast({
          title: "Error",
          description: `Failed to update user role: ${error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Selection Required",
        description: "Please select a user and a role.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={4}>
      <FormControl>
        <FormLabel>Select User</FormLabel>
        <Select
          placeholder="Select user"
          onChange={(e) => setSelectedUser(e.target.value)}
        >
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.displayName || user.email}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Select Role</FormLabel>
        <Select
          placeholder="Select role"
          onChange={(e) => setSelectedRole(e.target.value)}
        >
          <option value="Admin">Admin</option>
          <option value="Moderator">Moderator</option>
          <option value="AppUser">AppUser</option>
          <option value="UniversityUser">UniversityUser</option>
        </Select>
      </FormControl>
      <Button onClick={handleRoleChange} colorScheme="blue">
        Update Role
      </Button>
    </VStack>
  );
}

export default ManageUserRoles;
