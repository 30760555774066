import React, { useState, useEffect } from "react";
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  Input,
  Button,
  ButtonGroup,
  FormControl,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import ContractClausesService from "../services/Contract.services";
import { auth } from "../utils/init-firebase";

const AddContractClause = ({ id, setContractClauseId, refreshContractClauses }) => {
  const [ContractClause, setContractClause] = useState("");
  const [ContractClauseDescription, setContractClauseDescription] = useState("");
  const [Type, setType] = useState("Sporting");
  const [Competition, setCompetition] = useState("Domestic");
  const [IndividualCollective, setIndividualCollective] = useState("Individual");
  const [flag, setFlag] = useState(true);
  const [flag1, setFlag1] = useState(true);
  const [message, setMessage] = useState({ error: false, msg: "" });
  const { currentUser } = useAuth(auth);  // Get the current user from your auth context

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (ContractClause === "" || ContractClauseDescription === "") {
      setMessage({ error: true, msg: "All fields are mandatory!" });
      return;
    }
    const newContractClause = {
      ContractClause,
      ContractClauseDescription,
      Type,
      Competition,
      IndividualCollective
    };

    try {
      if (id !== undefined && id !== "") {
        await ContractClausesService.updateContractClause(id, newContractClause, currentUser);  // Pass current user
        setContractClauseId("");
        setMessage({ error: false, msg: "Contract Clause Updated successfully!" });
      } else {
        await ContractClausesService.addContractClauses(newContractClause, currentUser);  // Pass current user
        setMessage({ error: false, msg: "New Contract Clause added successfully!" });
      }

    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setContractClause("");
    setContractClauseDescription("");
    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  useEffect(() => {

    const editHandler = async () => {
      setMessage("");
      try {
        const docSnap = await ContractClausesService.getContractClause(id);
        setContractClause(docSnap.data().ContractClause);
        setContractClauseDescription(docSnap.data().ContractClauseDescription);
        setType(docSnap.data().status);
        setCompetition(docSnap.data().Competition); // Ensure the property name matches the database
        setIndividualCollective(docSnap.data().IndividualCollective)
      } catch (err) {
        setMessage({ error: true, msg: err.message });
      }
    };

    if (id !== undefined && id !== "") {
      editHandler();
    }
  }, [id]);

  return (
    <Box p={4} className="box">
      {message?.msg && (
        <Alert
          status={message?.error ? "error" : "success"}
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          rounded="md"
          mb={4}
          onClose={() => setMessage({ msg: "", error: false })}
        >
          <AlertIcon />
          <AlertTitle mt={4} mb={1}>
            {message?.msg}
          </AlertTitle>
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <FormControl id="formContractClause" mb={3}>
          <InputGroup>
            <InputLeftAddon children="A" />
            <Input
              type="text"
              placeholder="Contract Clause"
              value={ContractClause}
              onChange={(e) => setContractClause(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl id="formContractClausDescription" mb={3}>
          <InputGroup>
            <InputLeftAddon children="B" />
            <Input
              type="text"
              placeholder="Clause Variable Discription"
              value={ContractClauseDescription}
              onChange={(e) => setContractClauseDescription(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl mb={3}>
          <Text fontSize="md" mb={2}>
    Select a Type:
  </Text>
          <ButtonGroup spacing={4}>
            <Button
              variant={flag ? "solid" : "outline"}
              colorScheme="green"
              onClick={() => {
                setType("Sporting");
                setFlag(true);
              }}
            >
              Sporting
            </Button>
            <Button
              variant={!flag ? "solid" : "outline"}
              colorScheme="red"
              onClick={() => {
                setType("Non-Sporting");
                setFlag(false);
              }}
            >
              Non-Sporting
            </Button>
          </ButtonGroup>
        </FormControl>

        <FormControl mb={3}>
          <Text fontSize="md" mb={2}>
   Individual/Collective
  </Text>
          <ButtonGroup spacing={4}>
            <Button
              variant={flag1 ? "solid" : "outline"}
              colorScheme="messenger"
              onClick={() => {
                setIndividualCollective("Individual");
                setFlag1(true);
              }}
            >
              Individual
            </Button>
            <Button
              variant={!flag1 ? "solid" : "outline"}
              colorScheme="whatsapp"
              onClick={() => {
                setIndividualCollective("Collective");
                setFlag1(false);
              }}
            >
              Collective
            </Button>
          </ButtonGroup>
        </FormControl>

        <FormControl mb={3}>
           <Text fontSize="md" mb={2}>
 Competition
  </Text>
  <ButtonGroup spacing={4}>
    <Button
      variant={Competition === "Domestic" ? "solid" : "outline"}
      colorScheme="teal"
      onClick={() => setCompetition("Domestic")}
    >
      Domestic
    </Button>
    <Button
      variant={Competition === "Domestic Cup" ? "solid" : "outline"}
      colorScheme="purple"
      onClick={() => setCompetition("Domestic Cup")}
    >
      Domestic Cup
    </Button>
    <Button
      variant={Competition === "European" ? "solid" : "outline"}
      colorScheme="orange"
      onClick={() => setCompetition("European")}
    >
      European
    </Button>
    <Button
      variant={Competition === "International" ? "solid" : "outline"}
      colorScheme="pink"
      onClick={() => setCompetition("International")}
    >
      International
    </Button>
  </ButtonGroup>
</FormControl>


        <FormControl>
          <Button type="submit" colorScheme="blue">
            Add/Update
          </Button>
        </FormControl>
      </form>
    </Box>
  );
};

export default AddContractClause;
