import React from "react";
import { Box, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";

export function Footer() {
  const bg = useColorModeValue("white", "black");
  const color = useColorModeValue("black", "white");

  return (
    <Box
      as="footer"
      bg={bg}
      color={color}
      w="100%"
      p={0}
      m={0}
      display="flex"
      justifyContent="center" // Center the content horizontally
      alignItems="center" // Center the content vertically
    >
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3, lg: 5 }} // Responsive grid columns
        spacing={6}
        w="100%"
        maxW="container.lg" // Set a maximum width for the content
        mx="auto" // Center the grid content
        py={4} // Add padding for vertical centering
      >
        <Box textAlign="center">
          <Text fontWeight="bold" mb={2} fontSize={{ base: "lg", md: "xl" }}>
            Product
          </Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Features</Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Pricing</Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Documentation</Text>
        </Box>
        <Box textAlign="center">
          <Text fontWeight="bold" mb={2} fontSize={{ base: "lg", md: "xl" }}>
            Company
          </Text>
          <Text fontSize={{ base: "sm", md: "md" }}>About Us</Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Press</Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Blog</Text>
        </Box>
        <Box textAlign="center">
          <Text fontWeight="bold" mb={2} fontSize={{ base: "lg", md: "xl" }}>
            About
          </Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Team</Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Our Story</Text>
        </Box>
        <Box textAlign="center">
          <Text fontWeight="bold" mb={2} fontSize={{ base: "lg", md: "xl" }}>
            Careers
          </Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Open Positions</Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Internships</Text>
        </Box>
        <Box textAlign="center">
          <Text fontWeight="bold" mb={2} fontSize={{ base: "lg", md: "xl" }}>
            Contact
          </Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Email</Text>
          <Text fontSize={{ base: "sm", md: "md" }}>Support</Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
}
