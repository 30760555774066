import {
  Box,
  HStack,
  IconButton,
  Image,
  Tooltip,
  Text,
  useColorMode,
  useColorModeValue,
  Flex,
  VStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  FaMoon,
  FaSun,
  FaUserAlt,
  FaSignOutAlt,
  FaSignInAlt,
  FaUserPlus,
  FaBars,
  FaChalkboardTeacher,
} from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import SnowLeopardIcon from "../Icons/sl.png";
import SnowLeopardIcon1 from "../Icons/sl1.png";
import { db } from "../utils/init-firebase";
import { doc, getDoc } from "firebase/firestore";

export const Navbar = React.memo(function Navbar() {
  const { logout, currentUser } = useAuth();
  const { toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const [isAdmin, setIsAdmin] = useState(false);

  const navbarBg = useColorModeValue("whiteAlpha.100", "blackAlpha.100");
  //const navbarBg = useColorModeValue("white", "black");

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        try {
          const userProfileRef = doc(db, "userProfile", currentUser.uid);
          const userProfileSnap = await getDoc(userProfileRef);
          if (userProfileSnap.exists()) {
            const userProfile = userProfileSnap.data();
            setIsAdmin(userProfile.role === "Admin");
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  return (
    <Box mb={4} py={4} width="100%" bg={navbarBg}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        maxW="container.lg"
        mx="auto"
        px={4}
        flexWrap="wrap"
      >
        <NavLink to="/LandingPage">
          <Tooltip label="Landing Page" hasArrow placement="bottom">
            <IconButton
              icon={
                <Box display="flex" alignItems="center">
                  <Image
                    src={
                      colorMode === "light" ? SnowLeopardIcon : SnowLeopardIcon1
                    }
                    boxSize="220px" // Adjust size as needed
                    alt="Snow Leopard"
                  />
                </Box>
              }
              aria-label="Go to Landing Page"
              variant="ghost"
            />
          </Tooltip>
        </NavLink>

        {/* Mobile Menu Toggle */}
        <IconButton
          display={{ base: "flex", md: "none" }}
          icon={<FaBars />}
          aria-label="Open Menu"
          onClick={onOpen}
          variant="ghost"
          size="sm"
        />

        {/* Desktop Navigation */}
        <HStack
          spacing={4}
          display={{ base: "none", md: "flex" }}
          alignItems="center"
        >
          {!currentUser && (
            <>
              <NavLink to="/login">
                <Tooltip label="Log In" hasArrow placement="bottom">
                  <IconButton
                    icon={<FaSignInAlt />}
                    aria-label="Log In"
                    variant="ghost"
                  />
                </Tooltip>
              </NavLink>
              <NavLink to="/demo">
                <Tooltip label="Request Demo" hasArrow placement="bottom">
                  <IconButton
                    icon={<FaChalkboardTeacher />}
                    aria-label="Request Demo"
                    variant="ghost"
                  />
                </Tooltip>
              </NavLink>
            </>
          )}
          {currentUser && (
            <>
              <NavLink to="/profile">
                <Tooltip
                  label={
                    <Box>
                      <Text fontWeight="bold">
                        {currentUser.displayName || "No Display Name"}
                      </Text>
                      <Text fontSize="sm">
                        {currentUser.email || "No Email Available"}
                      </Text>
                    </Box>
                  }
                  fontSize="md"
                  placement="bottom"
                  hasArrow
                  bg="gray.300"
                  color="black"
                >
                  <IconButton
                    icon={<FaUserAlt />}
                    aria-label="Profile"
                    variant="ghost"
                  />
                </Tooltip>
              </NavLink>
              {isAdmin && (
                <NavLink to="/register">
                  <Tooltip
                    label="Register New User"
                    hasArrow
                    placement="bottom"
                  >
                    <IconButton
                      icon={<FaUserPlus />}
                      aria-label="Request Demo"
                      variant="ghost"
                    />
                  </Tooltip>
                </NavLink>
              )}
              <NavLink to="/logout">
                <Tooltip
                  label="Logout"
                  fontSize="md"
                  placement="bottom"
                  hasArrow
                >
                  <IconButton
                    icon={<FaSignOutAlt />}
                    aria-label="Logout"
                    variant="ghost"
                    onClick={async (e) => {
                      e.preventDefault();
                      await logout();
                    }}
                  />
                </Tooltip>
              </NavLink>
            </>
          )}
          <IconButton
            icon={useColorModeValue(<FaSun />, <FaMoon />)}
            onClick={toggleColorMode}
            aria-label="toggle-dark-mode"
            variant="ghost"
          />
        </HStack>

        {/* Mobile Drawer Menu with Custom Size */}
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent maxW="200px">
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" width="100%">
              Menu
            </DrawerHeader>
            <DrawerBody>
              <VStack spacing={4}>
                <IconButton
                  icon={useColorModeValue(<FaSun />, <FaMoon />)}
                  onClick={toggleColorMode}
                  aria-label="toggle-dark-mode"
                  variant="ghost"
                />
                {!currentUser && (
                  <>
                    <NavLink to="/login">
                      <IconButton
                        icon={<FaSignInAlt />}
                        aria-label="Log In"
                        variant="ghost"
                      />
                    </NavLink>
                  </>
                )}
                {currentUser && (
                  <>
                    <NavLink to="/profile">
                      <IconButton
                        icon={<FaUserAlt />}
                        aria-label="Profile"
                        variant="ghost"
                      />
                    </NavLink>
                    <NavLink to="/logout">
                      <IconButton
                        icon={<FaSignOutAlt />}
                        aria-label="Logout"
                        variant="ghost"
                        onClick={async (e) => {
                          e.preventDefault();
                          await logout();
                        }}
                      />
                    </NavLink>
                  </>
                )}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
});
