import React from "react";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

const BackgroundWrapper = ({ children }) => {
  const location = useLocation();
  const isRegisterPage = location.pathname === "/register";

  return <Box minHeight="100vh">{children}</Box>;
};

export default BackgroundWrapper;
