import React, { useState, useRef, useEffect } from "react";
import { Box, Container, useColorModeValue } from "@chakra-ui/react";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer"; // Import the Footer component

export function Layout({ children }) {
  const [isFooterExpanded, setIsFooterExpanded] = useState(false);
  const footerRef = useRef(null); // Reference to the footer container

  // Determine the footer background color based on color mode
  const expandedBg = useColorModeValue("white", "black"); // Solid background color for expanded state
  const collapsedBg = useColorModeValue("whiteAlpha.100", "blackAlpha.100"); // Translucent background color for collapsed state
  //const collapsedBg = useColorModeValue("white", "black");
  // Set the footer background color based on the expanded state
  const footerBg = isFooterExpanded ? expandedBg : collapsedBg;

  // Toggle footer expand/collapse state
  const toggleFooter = () => {
    setIsFooterExpanded(!isFooterExpanded);
  };

  // Collapse footer on mouse leave
  const handleMouseLeave = () => {
    if (isFooterExpanded) {
      setIsFooterExpanded(false);
    }
  };

  // Collapse footer on scroll up
  const handleScroll = () => {
    if (isFooterExpanded) {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      if (scrollY === 0) {
        setIsFooterExpanded(false);
      }
    }
  };

  // Collapse footer when tapping outside on mobile
  const handleOutsideClick = (e) => {
    if (
      isFooterExpanded &&
      footerRef.current &&
      !footerRef.current.contains(e.target)
    ) {
      setIsFooterExpanded(false);
    }
  };

  useEffect(() => {
    // Add event listeners for scroll and click
    window.addEventListener("scroll", handleScroll, { passive: true });
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);

    return () => {
      // Remove event listeners on cleanup
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, [isFooterExpanded]);

  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      position="relative"
      overflow="hidden" // Prevent the page from scrolling when the footer expands
    >
      <Navbar />
      <Container maxW="container.lg" px={6} flex="1" overflowY="auto">
        {children}
      </Container>
      {/* Collapsible Footer Ribbon */}
      <Box
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        bg={footerBg} // Dynamic background color
        zIndex={1000}
        transition="height 0.3s ease-in-out, background-color 0.3s ease-in-out" // Smooth transition for height and color
        height={isFooterExpanded ? "200px" : "40px"} // Expanded height vs collapsed height
        overflow="hidden" // Hide overflow by default
        cursor="pointer" // Change cursor to pointer to indicate clickable area
        onClick={toggleFooter} // Make the entire ribbon clickable
        onMouseLeave={handleMouseLeave} // Auto-collapse when the mouse leaves the footer
        p={0} // Remove any padding from the footer
        m={0} // Remove any margin from the footer
      >
        {/* Footer Content Container */}
        <Box
          ref={footerRef} // Reference for scrolling
          overflowY="auto" // Allow scrolling inside this container
          height="100%"
          width="100%"
          p={4} // Padding inside the content area
        >
          {isFooterExpanded && <Footer />}
          {/* Render Footer only when expanded */}
        </Box>
      </Box>
    </Box>
  );
}
