import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  VStack,
  HStack,
  Text,
  Textarea,
  Button,
  Switch,
  Divider,
  Flex,
  Spinner,
  IconButton,
  useColorModeValue,
  Tooltip,
  Image,
} from "@chakra-ui/react";
import {
  AttachmentIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { Layout } from "../components/Layout";
import { useColorMode } from "@chakra-ui/react";
import "../CSS/AIChat.css";

// Import icons
import openaiIcon from "../Icons/ChatGPT.png";
import claudeIcon from "../Icons/Claud.png";
import geminiIcon from "../Icons/Gemini.png";
import PerplexityIcon from "../Icons/Perplexity.png";

const MODEL_CONFIG = {
  openai: {
    icon: openaiIcon,
    title: "Chat GPT",
    color: "green.500",
  },
  claude: {
    icon: claudeIcon,
    title: "Claude Assistant",
    color: "orange.500",
  },
  gemini: {
    icon: geminiIcon,
    title: "Gemini AI",
    color: "blue.500",
  },
  Perplexity: {
    icon: PerplexityIcon,
    title: "Perplexity",
    color: "purple.500",
  },
};

const AIChat = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [input, setInput] = useState("");
  const [selectedModel, setSelectedModel] = useState("openai");
  const [selectedInterface, setSelectedInterface] = useState("web");
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [isHistoryOpen, setIsHistoryOpen] = useState(true);
  const responseBoxRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { colorMode } = useColorMode();

  const responseTextColor = useColorModeValue("black", "white");
  const backgroundColor = useColorModeValue("gray.50", "gray.900");
  const chatHistoryBg = useColorModeValue("white", "gray.700");
  const responseBoxBg = useColorModeValue("gray.100", "gray.600");

  // Get current model color
  const modelColor = MODEL_CONFIG[selectedModel].color;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const modelParam = params.get("model");
    if (modelParam && MODEL_CONFIG[modelParam]) {
      setSelectedModel(modelParam);
    }
  }, [location]);

  // Auto scroll effect
  useEffect(() => {
    if (responseBoxRef.current) {
      responseBoxRef.current.scrollTop = responseBoxRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleModelChange = (model) => {
    if (MODEL_CONFIG[model]) {
      setSelectedModel(model);
      navigate(`/AIChat?model=${model}`);
      setChatHistory([]);
    }
  };

  // Update InterfaceSwitch component
  const InterfaceSwitch = ({ label, interfaceType }) => (
    <HStack className="interface-option" spacing={1}>
      <Text fontSize="sm">{label}</Text>
      <Switch
        isChecked={selectedInterface === interfaceType}
        onChange={() => handleInterfaceChange(interfaceType)}
        colorScheme={MODEL_CONFIG[selectedModel].color.split(".")[0]}
        size="md"
      />
    </HStack>
  );

  const handleInterfaceChange = (interfaceType) => {
    setSelectedInterface(interfaceType);
  };

  // Update ModelSwitch component
  const ModelSwitch = ({ label, model }) => (
    <HStack className="model-option" spacing={1}>
      <Image
        src={MODEL_CONFIG[model].icon}
        alt={label}
        boxSize="20px"
        objectFit="contain"
      />
      <Text fontSize="sm">{label}</Text>
      <Switch
        isChecked={selectedModel === model}
        onChange={() => handleModelChange(model)}
        colorScheme={MODEL_CONFIG[model].color.split(".")[0]}
        size="md"
      />
    </HStack>
  );

  const handleSendMessage = async () => {
    if (!input.trim() && attachments.length === 0) return;

    setLoading(true);
    const newMessage = { sender: "user", message: input };
    setChatHistory([...chatHistory, newMessage]);

    const attachmentData = attachments.map((file) => ({
      name: file.name,
      type: file.file.type,
      content: file.content, // Send the complete content
    }));

    const modelToUse =
      selectedModel === "openai" ? "gpt-3.5-turbo" : selectedModel;

    try {
      // ... (Get ID token if needed)

      const response = await fetch(
        "https://getopenairesponse-zlbmixsola-uc.a.run.app/", // Replace with your function URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // ... (Authorization header if needed)
          },
          body: JSON.stringify({
            message: input, // Make sure 'input' has the correct value
            model: modelToUse,
            attachments: attachmentData,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Server error: ${errorData.error || response.statusText}`
        );
      }

      const responseData = await response.json();
      const responseMessage = responseData.message;

      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: modelToUse, message: responseMessage },
      ]);
    } catch (error) {
      console.error("Error fetching response:", error.message || error);
    } finally {
      setInput("");
      setLoading(false);
      setAttachments([]);
    }
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const uploadedFiles = await Promise.all(
      Array.from(files).map(async (file) => {
        const content = await fileToBase64(file);
        return {
          name: file.name,
          size: file.size,
          file,
          content,
        };
      })
    );
    setAttachments([...attachments, ...uploadedFiles]);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const removeAttachment = (fileName) => {
    setAttachments(attachments.filter((file) => file.name !== fileName));
  };

  // Function to clear chat history
  const clearChatHistory = () => {
    setChatHistory([]);
  };

  return (
    <Layout>
      <div className={`chat-container ${colorMode}-mode`}>
        {/* Header */}
        <div className="chat-header">
          <HStack
            spacing="4"
            alignItems="center"
            justifyContent={["center", "flex-start"]}
            flexDirection={["column", "row"]}
          >
            <Image
              src={MODEL_CONFIG[selectedModel].icon}
              alt={MODEL_CONFIG[selectedModel].title}
              boxSize="40px"
              objectFit="contain"
            />
            <Text
              fontSize="2xl"
              fontWeight="bold"
              color={MODEL_CONFIG[selectedModel].color}
            >
              {MODEL_CONFIG[selectedModel].title}
            </Text>
          </HStack>
        </div>

        {/* Chat History Panel */}
        <div className={`history-panel ${isHistoryOpen ? "open" : ""}`}>
          <div className="history-panel-header">
            {isHistoryOpen && (
              <Text className="history-title">Chat History</Text>
            )}
            <IconButton
              className="history-toggle-button"
              icon={isHistoryOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              onClick={() => setIsHistoryOpen(!isHistoryOpen)}
              aria-label="Toggle Chat History"
              size="sm"
            />
          </div>

          <div className="history-panel-content">
            <VStack align="stretch" spacing="3">
              {chatHistory.map((chat, index) => (
                <Box key={index} className="chat-message">
                  <Text fontSize="sm" opacity={0.8}>
                    {chat.sender}:
                  </Text>
                  <Text>{chat.message}</Text>
                </Box>
              ))}
            </VStack>

            {chatHistory.length > 0 && (
              <Button
                className="clear-history-button"
                colorScheme="red"
                onClick={clearChatHistory}
                size="sm"
              >
                Clear Chat History
              </Button>
            )}
          </div>
        </div>

        {/* Main Chat Area */}
        <div className="main-chat-area">
          {/* Model Selection */}
          <HStack spacing="4" className="model-selection" mb={1}>
            <Text fontSize="md" fontWeight="bold" whiteSpace="nowrap">
              Choose Model:
            </Text>
            <Flex
              gap="2"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <ModelSwitch label="OpenAI" model="openai" />
              <ModelSwitch label="Claude" model="claude" />
              <ModelSwitch label="Gemini" model="gemini" />
              <ModelSwitch label="Perplexity" model="Perplexity" />
            </Flex>
          </HStack>

          {/* Interface Selection */}
          <HStack spacing="4" className="interface-selection" mb={1}>
            <Text fontSize="md" fontWeight="bold" whiteSpace="nowrap">
              Choose Interface:
            </Text>
            <Flex
              gap="2"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <InterfaceSwitch label="Web" interfaceType="web" />
              <InterfaceSwitch label="VMD Box" interfaceType="VMD Box" />
            </Flex>
          </HStack>

          {/* Response Box */}
          <div
            className="response-container"
            style={{ backgroundColor: responseBoxBg }}
          >
            <div className="response-header">
              <Text fontSize="lg" fontWeight="bold" color={responseTextColor}>
                Response
              </Text>
              <Divider mb={2} />
            </div>

            <div
              className="response-content custom-scrollbar"
              ref={responseBoxRef}
            >
              {loading ? (
                <Flex justify="center" align="center" height="100%">
                  <Spinner thickness="4px" speed="0.65s" color={modelColor} />
                </Flex>
              ) : (
                <VStack align="stretch" spacing="4">
                  {chatHistory
                    .filter((chat) => chat.sender !== "user")
                    .map((chat, index) => (
                      <div key={index} className="response-message">
                        <Text>{chat.message}</Text>
                      </div>
                    ))}
                </VStack>
              )}
            </div>
          </div>

          {/* Chat Input Area */}
          <div className="chat-input-container">
            <div className="chat-input-wrapper">
              <Tooltip label="Attach File" fontSize="md">
                <IconButton
                  icon={<AttachmentIcon />}
                  colorScheme={modelColor.split(".")[0]}
                  onClick={() => document.getElementById("fileUpload").click()}
                  aria-label="Attach File"
                  className="attach-button model-color-hover"
                />
              </Tooltip>
              <input
                type="file"
                id="fileUpload"
                style={{ display: "none" }}
                multiple
                onChange={handleFileUpload}
              />
              <Textarea
                placeholder="Enter your message"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" && !e.shiftKey && handleSendMessage()
                }
                flex="1"
                rows={2}
                resize="none"
              />
              <Button
                colorScheme={modelColor.split(".")[0]}
                onClick={handleSendMessage}
                isLoading={loading}
                isDisabled={
                  loading || (!input.trim() && attachments.length === 0)
                }
                className="send-button model-color-hover"
              >
                Send
              </Button>
            </div>

            {/* Attachments */}
            <div className="attachments-list">
              {attachments.map((file, index) => (
                <div key={index} className="attachment-item">
                  <Text fontSize="sm">{file.name}</Text>
                  <IconButton
                    icon={<CloseIcon />}
                    size="xs"
                    onClick={() => removeAttachment(file.name)}
                    aria-label="Remove attachment"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AIChat;
