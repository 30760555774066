import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  VStack,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  HStack,
  useColorModeValue, // Make sure this is imported at the top
} from "@chakra-ui/react";
import { auth } from "../utils/init-firebase";
import { Layout } from "../components/Layout";
import {
  BarChart,
  Bar,
  XAxis,
  Cell,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CB = () => {
  const [leagueId, setLeagueId] = useState(
    sessionStorage.getItem("leagueId") || "39"
  );
  const [season, setSeason] = useState(
    sessionStorage.getItem("season") || "2023"
  );
  const [champions, setChampions] = useState(
    JSON.parse(sessionStorage.getItem("champions") || "[]")
  );
  const [pointsData, setPointsData] = useState(
    JSON.parse(sessionStorage.getItem("pointsData") || "[]")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingPointsData, setLoadingPointsData] = useState(false);

  // Move useColorModeValue outside of conditional logic
  const backgroundColor = useColorModeValue("gray.50", "gray.800");

  const handleFetchChampions = async () => {
    if (!leagueId || !season) {
      setError("Please enter both League ID and Season.");
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      const userToken = await auth.currentUser.getIdToken();
      const response = await fetch(
        `https://api-zlbmixsola-uc.a.run.app/api/champion?leagueID=${leagueId}&season=${season}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error fetching data");
      }

      const data = await response.json();
      setChampions(data); // Assuming setChampions is the state setter for displaying champions
      // After fetching champions, fetch points data
      await handleFetchPointsData(); // Call the separate function to fetch points data
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const countOccurrences = (champions) => {
    const countMap = {};
    champions.forEach((champion) => {
      countMap[champion.champion] = (countMap[champion.champion] || 0) + 1;
    });
    return Object.keys(countMap).map((key) => ({
      name: key,
      count: countMap[key],
    }));
  };

  // In your component
  const chartData = countOccurrences(champions);

  const transformDataForSingleStackedBar = (champions) => {
    const transformedData = champions.reduce((acc, { champion }) => {
      acc[champion] = (acc[champion] || 0) + 1;
      return acc;
    }, {});

    return [transformedData]; // Wrap in an array to match expected data structure
  };

  // Prepare chart data from pointsData
  const chartData1 = pointsData.map((team) => ({
    teamName: team.teamName,
    avgPointsPerGame:
      team.gamesPlayed > 0
        ? parseFloat((team.points / team.gamesPlayed).toFixed(2))
        : 0,
  }));

  const handleFetchPointsData = async () => {
    if (!leagueId || !season) {
      setError("Please enter both League ID and Season.");
      return;
    }

    setError(null);
    setLoadingPointsData(true);

    try {
      const userToken = await auth.currentUser.getIdToken();
      const response = await fetch(
        `https://api-zlbmixsola-uc.a.run.app/api/points?leagueID=${leagueId}&season=${season}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error fetching points data");
      }

      const data = await response.json();
      setPointsData(data);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setLoadingPointsData(false);
    }
  };

  const singleStackedData = transformDataForSingleStackedBar(champions);

  const colors = {}; // Object to store colors for each champion
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  champions.forEach((champion) => {
    if (!colors[champion.champion]) {
      colors[champion.champion] = getRandomColor();
    }
  });

  const chartContent =
    singleStackedData && singleStackedData.length > 0
      ? Object.entries(singleStackedData[0]).map(([key, value], index) => (
          <Cell key={`cell-${index}`} fill={colors[key] || getRandomColor()} />
        ))
      : null;

  const topTeamPoints = pointsData.length > 0 ? pointsData[0].points : 0;

  // Add Points Difference to each team's data
  const pointsWithDifference = pointsData.map((team) => ({
    ...team,
    pointsDifference: topTeamPoints - team.points,
  }));

  // Add Points Difference to each team's data
  const pointsWithNextTeamDifference = pointsData.map((team, index, array) => {
    // Calculate the difference with the next team, except for the last team
    const pointsDifferenceWithNext =
      index < array.length - 1 ? team.points - array[index + 1].points : 0;

    return {
      ...team,
      pointsDifferenceWithNext,
    };
  });

  // Assuming pointsWithNextTeamDifference contains the required data
  const chartData2 = pointsWithNextTeamDifference.map((team) => ({
    name: team.teamName,
    PointsDiffNext: team.pointsDifferenceWithNext,
  }));

  // Prepare chart data from pointsWithDifference
  const chartData3 = pointsWithDifference.map((team) => ({
    name: team.teamName,
    PointsDiff: team.pointsDifference, // Assuming pointsWithDifference includes a pointsDifference field
  }));

  useEffect(() => {
    // Set isLoading to true when any of the data-fetching processes start
    // and to false when all data-fetching processes have completed
    const fetchData = async () => {
      setIsLoading(true);
      // Call your data-fetching functions here (e.g., handleFetchChampions, handleFetchPointsData)
      // Ensure you await their completion before setting isLoading to false
      setIsLoading(false);
    };

    if (leagueId && season) {
      fetchData();
    }
  }, [leagueId, season]); // Depend on leagueId and season so fetchData runs when they change

  const handleClearData = () => {
    setChampions([]); // Clear champions data
    setPointsData([]); // Clear points data
    // Reset any other relevant state variables
    // Clear specific items from session storage
    sessionStorage.removeItem("champions");
    sessionStorage.removeItem("pointsData");
    sessionStorage.removeItem("leagueId");
    sessionStorage.removeItem("season");
  };

  // Effect to store leagueId and season in sessionStorage when they change
  useEffect(() => {
    sessionStorage.setItem("leagueId", leagueId);
    sessionStorage.setItem("season", season);
  }, [leagueId, season]);

  useEffect(() => {
    sessionStorage.setItem("champions", JSON.stringify(champions));
    sessionStorage.setItem("pointsData", JSON.stringify(pointsData));
  }, [champions, pointsData]);

  return (
    <Layout>
      {isLoading ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      ) : (
        <Box
          p={4}
          minHeight="100vh" // Ensure the background fits the full height of the viewport
          bg={backgroundColor} // Use the value from the hook
        >
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={4}
            mb={6}
          >
            <Flex align="start" justify="space-between">
              <VStack align="start" flex="1" mr={4}>
                <FormLabel htmlFor="leagueId" mb={1}>
                  League ID
                </FormLabel>
                <Input
                  id="leagueId"
                  placeholder="League ID"
                  value={leagueId}
                  onChange={(e) => setLeagueId(e.target.value)}
                />
              </VStack>

              <VStack align="start" flex="1" mr={4}>
                <FormLabel htmlFor="season" mb={1}>
                  Season
                </FormLabel>
                <Input
                  id="season"
                  placeholder="Season"
                  value={season}
                  onChange={(e) => setSeason(e.target.value)}
                />
              </VStack>

              <HStack spacing={4} mt={8}>
                <Button
                  colorScheme="blue"
                  onClick={handleFetchChampions}
                  width="full"
                >
                  Calculate
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleClearData}
                  width="full"
                >
                  Clear
                </Button>
              </HStack>
            </Flex>
          </Box>
          <Tabs>
            <TabList>
              <Tab>Champions</Tab>
              <Tab>Avg. Points</Tab>
              <Tab>Points diffrence to the first team</Tab>
              <Tab>Points Difference between consicutve team</Tab>
              <Tab>Tableau Dashboard</Tab>{" "}
              {/* Add a new Tab for the Tableau Dashboard */}
            </TabList>

            <TabPanels>
              <TabPanel>
                {/* Champions Table */}
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  textDecoration="underline"
                  mb={4}
                >
                  Number of Different Champions in the Last 5 Seasons
                </Text>
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  mb={6}
                >
                  {champions.length > 0 && (
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Season</Th>
                          <Th>Champion</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {champions.map((champion, index) => (
                          <Tr key={index}>
                            <Td>{champion.season}</Td>
                            <Td>{champion.champion}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  )}
                </Box>

                {/* Champions Chart */}
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  mb={6}
                >
                  <BarChart width={600} height={300} data={chartData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#8884d8" />
                  </BarChart>
                </Box>

                {/* Stacked Bar Chart */}
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                >
                  <BarChart width={600} height={300} data={singleStackedData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {Object.keys(singleStackedData[0]).map((key) => (
                      <Bar
                        dataKey={key}
                        stackId="a"
                        fill={colors[key] || getRandomColor()}
                        key={key}
                      />
                    ))}
                  </BarChart>
                </Box>
              </TabPanel>
              <TabPanel>
                {/* Points Data Table moved to this TabPanel */}
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  textDecoration="underline"
                  mb={4}
                >
                  AVG. Points per game / season
                </Text>
                {!loadingPointsData && pointsData.length > 0 && (
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    p={4}
                    mb={6}
                  >
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>#</Th> {/* New Column Header for numbering */}
                          <Th>Team</Th>
                          <Th isNumeric>Games Played</Th>
                          <Th isNumeric>Points</Th>
                          <Th isNumeric>Avg Points Per Game</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {pointsData.map((team, index) => {
                          const avgPointsPerGame =
                            team.gamesPlayed > 0
                              ? (team.points / team.gamesPlayed).toFixed(2)
                              : 0;
                          return (
                            <Tr key={index}>
                              <Td>{index + 1}</Td> {/* Row numbering */}
                              <Td>{team.teamName}</Td>
                              <Td isNumeric>{team.gamesPlayed}</Td>
                              <Td isNumeric>{team.points}</Td>
                              <Td isNumeric>{avgPointsPerGame}</Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </Box>
                )}
                {/* Bar Chart for Avg Points Per Game */}
                {!loadingPointsData && pointsData.length > 0 && (
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    p={4}
                    mb={6}
                  >
                    <Text fontSize="xl" fontWeight="bold" mb={4}>
                      Average Points Per Game
                    </Text>
                    <Box mt={4}>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          data={chartData1}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <XAxis dataKey="teamName" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="avgPointsPerGame" fill="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </Box>
                  </Box>
                )}
              </TabPanel>
              <TabPanel>
                {/* Points Difference Table */}
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  textDecoration="underline"
                  mb={4}
                >
                  Points diffrence to the first team
                </Text>
                {!loadingPointsData && pointsWithDifference.length > 0 && (
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    p={4}
                    mb={6}
                  >
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>#</Th>
                          <Th>Team</Th>
                          <Th isNumeric>Games Played</Th>
                          <Th isNumeric>Points</Th>
                          <Th isNumeric>Points Difference</Th>{" "}
                          {/* New Column */}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {pointsWithDifference.map((team, index) => (
                          <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{team.teamName}</Td>
                            <Td isNumeric>{team.gamesPlayed}</Td>
                            <Td isNumeric>{team.points}</Td>
                            <Td isNumeric>{team.pointsDifference}</Td>{" "}
                            {/* Points Difference Data */}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  mb={6}
                >
                  <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Points Difference from the Top Team
                  </Text>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={chartData3}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="PointsDiff"
                        fill="#82ca9d"
                        name="Points Difference from Top"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </TabPanel>
              <TabPanel>
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  textDecoration="underline"
                  mb={4}
                >
                  Points diffrence to the consecutive team
                </Text>
                {!loadingPointsData &&
                  pointsWithNextTeamDifference.length > 0 && (
                    <Box
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      p={4}
                      mb={6}
                    >
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Team</Th>
                            <Th isNumeric>Games Played</Th>
                            <Th isNumeric>Points</Th>
                            <Th isNumeric>Diff with Next Team</Th>{" "}
                            {/* New Column for difference with the next team */}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {pointsWithNextTeamDifference.map((team, index) => (
                            <Tr key={index}>
                              <Td>{index + 1}</Td>
                              <Td>{team.teamName}</Td>
                              <Td isNumeric>{team.gamesPlayed}</Td>
                              <Td isNumeric>{team.points}</Td>
                              <Td isNumeric>
                                {team.pointsDifferenceWithNext}
                              </Td>{" "}
                              {/* Points Difference with the next team */}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  )}
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  mb={6}
                >
                  <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Points Difference with Next Team
                  </Text>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={chartData2}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="PointsDiffNext"
                        fill="#8884d8"
                        name="Points Difference with Next"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </TabPanel>
              <TabPanel>
                {/* New TabPanel for Tableau Dashboard */}
                <iframe
                  src="https://public.tableau.com/views/POL_MT_EXT/Dashboard1?:showVizHome=no&:embed=true"
                  width="100%"
                  height="500px"
                  title="Tableau Dashboard"
                  frameBorder="0"
                ></iframe>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </Layout>
  );
};

export default CB;
