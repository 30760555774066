import { extendTheme } from "@chakra-ui/react";
//import LightBG from "../images/Gradient4.png"; // Background for light mode
//import DarkBG from "../images/Gradient3.png"; // Background for dark mode

const customTheme = {
  styles: {
    global: (props) => ({
      "html, body": {
        height: "100%",
      },
      body: {
        bg: props.colorMode === 'dark' ? '#060606' : 'gray.50',
        color: props.colorMode === 'dark' ? 'white' : 'gray.800',
        backgroundImage: props.colorMode === "light" ? "#FFFFFF" : "#000000",
        //backgroundImage: props.colorMode === 'light' ? `url(${LightBG})` : `url(${DarkBG})`,
        backgroundSize: 'cover', // Ensures the background covers the entire area
        backgroundPosition: 'center center', // Centers the background image
        backgroundRepeat: 'no-repeat', // Prevents the background image from repeating
        backgroundAttachment: 'fixed', // Keeps the background fixed during scrolling
        minHeight: '100vh',
      },
    }),
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
      },
    },
    Input: {
      baseStyle: (props) => ({
        field: {
          bg: props.colorMode === "dark" ? "gray.800" : "gray.100",
        },
      }),
    },
  },
};

const theme = extendTheme(customTheme);

export default theme;
