import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Box,
  Flex,
  IconButton,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tooltip,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon, RepeatIcon, CopyIcon } from "@chakra-ui/icons";
import PlayerService from "../services/Player.services";
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../utils/init-firebase";

const PlayersList = ({ getPlayerId }) => {
  const [Players, setPlayers] = useState([]);
  const { currentUser } = useAuth(auth);

  useEffect(() => {
    getPlayers();
  }, []);

  const getPlayers = async () => {
    const data = await PlayerService.getAllPlayers();
    setPlayers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteHandler = async (id) => {
    await PlayerService.deletePlayer(id);
    getPlayers(); // Refresh the list after deleting a Players
  };

  const duplicateHandler = async (Player) => {
    const duplicatePlayer = { ...Player, Name: `Copy of ${Player.Name}` };
    await PlayerService.addPlayers(duplicatePlayer, currentUser);
    getPlayers(); // Refresh the list after duplicating a Players
  };

  return (
    <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Flex justify="space-between" mb={2}>
        <Button
          leftIcon={<RepeatIcon />}
          colorScheme="gray"
          onClick={getPlayers}
        >
          Refresh List
        </Button>
      </Flex>

      <Table variant="striped" colorScheme="gray" size="sm">
        <TableCaption>Player List</TableCaption>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Name</Th>
            <Th>Club</Th>
            <Th>Nationality</Th>
            <Th>Position</Th>
            <Th>League</Th>
            <Th>Club Country</Th>
            <Th>Tier</Th>
            <Th>Player ID</Th>
            <Th>Season</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Players.map((Player, index) => (
            <Tr key={Player.id}>
              <Td>{index + 1}</Td>
              <Td>{Player.Name}</Td>
              <Td>{Player.Club}</Td>
              <Td>{Player.Nationality}</Td>
              <Td>{Player.Position}</Td>
              <Td>{Player.League}</Td>
              <Td>{Player.clubCountry}</Td>
              <Td>{Player.Tier}</Td>
               <Td>{Player.PlayerID}</Td>
               <Td>{Player.Season}</Td>
              <Td>
                <Tooltip label="Edit">
                  <IconButton
                    aria-label="Edit"
                    icon={<EditIcon />}
                    colorScheme="teal"
                    onClick={() => getPlayerId(Player.id)}
                    mr={2}
                  />
                </Tooltip>
                <Tooltip label="Delete">
                  <IconButton
                    aria-label="Delete"
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    onClick={() => deleteHandler(Player.id)}
                    mr={2}
                  />
                </Tooltip>
                <Tooltip label="Duplicate">
                  <IconButton
                    aria-label="Duplicate"
                    icon={<CopyIcon />}
                    colorScheme="blue"
                    onClick={() => duplicateHandler(Player)}
                  />
                </Tooltip>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default PlayersList;
