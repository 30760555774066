import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Box,
  Flex,
  IconButton,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tooltip,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon, RepeatIcon, CopyIcon } from "@chakra-ui/icons";
import ContractClausesService from "../services/Contract.services";
import { useAuth } from '../contexts/AuthContext';
import { auth } from "../utils/init-firebase";

export const useContractClauses = () => {
  const [ContractClauses, setContractClauses] = useState([]);

  useEffect(() => {
    const getContractClauses = async () => {
      const data = await ContractClausesService.getAllContractClauses();
      setContractClauses(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getContractClauses();
  }, []);

  return ContractClauses;
};

const ContractClausesList = ({ getContractClauseId }) => {
  const [ContractClauses, setContractClauses] = useState([]);
  const { currentUser } = useAuth(auth);


  useEffect(() => {
    getContractClauses();
  }, []);

  const getContractClauses = async () => {
    const data = await ContractClausesService.getAllContractClauses();
    setContractClauses(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteHandler = async (id) => {
    await ContractClausesService.deleteContractClause(id);
    getContractClauses(); // Refresh the list after deleting a Contract Clauses
  };

  const duplicateHandler = async (ContractClause) => {
    const duplicateContractClause = { ...ContractClause, ContractClause: `Copy of ${ContractClause.ContractClause}` };
    await ContractClausesService.addContractClauses(duplicateContractClause,currentUser);
    getContractClauses(); // Refresh the list after duplicating a Contract Clauses
  };

  return (
    <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Flex justify="space-between" mb={2}>
        <Button leftIcon={<RepeatIcon />} colorScheme="gray" onClick={getContractClauses}>
          Refresh List
        </Button>
      </Flex>

      <Table variant="striped" colorScheme="gray" size="sm">
        <TableCaption >Contract Clause List</TableCaption>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Individual/Collective</Th>
            <Th>Competition</Th>
            <Th>Clause</Th>
            <Th>Variable Description</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {ContractClauses.map((ContractClause, index) => (
            <Tr key={ContractClause.id}>
              <Td>{index + 1}</Td>
              <Td>{ContractClause.IndividualCollective}</Td>
              <Td>{ContractClause.Competition}</Td>
              <Td>{ContractClause.ContractClause}</Td>
              <Td>{ContractClause.ContractClauseDescription}</Td>
              <Td>
                <Tooltip label="Edit">
                  <IconButton
                    aria-label="Edit"
                    icon={<EditIcon />}
                    colorScheme="teal"
                    onClick={() => getContractClauseId(ContractClause.id)}
                    mr={2}
                  />
                </Tooltip>
                <Tooltip label="Delete">
                  <IconButton
                    aria-label="Delete"
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    onClick={() => deleteHandler(ContractClause.id)}
                    mr={2}
                  />
                </Tooltip>
                <Tooltip label="Duplicate">
                  <IconButton
                    aria-label="Duplicate"
                    icon={<CopyIcon />}
                    colorScheme="blue"
                    onClick={() => duplicateHandler(ContractClause)}
                  />
                </Tooltip>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ContractClausesList;
