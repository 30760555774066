import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  VStack,
  HStack,
  Button,
  Text,
  Stack,
  Icon,
} from "@chakra-ui/react";
import { FaChartBar, FaTable, FaChartPie } from "react-icons/fa";
import { Layout } from "../components/Layout";

const Shineyapp = () => {
  // Set the default iframe to the first visualization
  const [currentIframe, setCurrentIframe] = useState(
    "https://varun-muralidhar.shinyapps.io/snowleopard"
  );

  const iframeList = [
    {
      src: "https://varun-muralidhar.shinyapps.io/snowleopard",
      title: "VIZ 1",
      icon: FaChartBar,
    },
    {
      src: "https://public.tableau.com/views/OF-MT-NEW-3/Dashboard7?:showVizHome=no&:embed=true",
      title: "VIZ 2",
      icon: FaTable,
    },
    {
      src: "https://public.tableau.com/views/CAP_APP_OVERVIEW/Story4?:showVizHome=no&:embed=true",
      title: "VIZ 3",
      icon: FaChartPie,
    },
  ];

  return (
    <Layout>
      <ChakraProvider>
        <HStack height="100vh" spacing={0}>
          <VStack
            spacing={4}
            align="stretch"
            bg="black"
            padding={4}
            width="10%" // Adjust width to make the ribbon smaller
            height="100vh"
          >
            {iframeList.map((iframe, index) => (
              <Button
                key={index}
                onClick={() => setCurrentIframe(iframe.src)}
                color="white"
                justifyContent="flex-start"
                variant={currentIframe === iframe.src ? "solid" : "ghost"}
                bg={currentIframe === iframe.src ? "gray.600" : "black"} // Adjust background color when selected
                _hover={{ bg: "gray.500" }} // Add hover effect
                _active={{ bg: "gray.600" }} // Add active effect
                py={6} // Increase padding to make the shaded area bigger
              >
                <Stack align="center" spacing={1}>
                  <Icon as={iframe.icon} w={6} h={6} />{" "}
                  {/* Increased icon size */}
                  <Text fontSize="xs">{iframe.title}</Text>
                </Stack>
              </Button>
            ))}
          </VStack>
          <Box
            flex="1"
            height="100vh"
            padding={4} // Add padding between the black ribbon and the iframe
            overflow="hidden"
            bg="white"
            display="flex"
            flexDirection="column" // Ensure that items are stacked vertically
            alignItems="center"
            justifyContent="flex-start" // Align items to the start to match the ribbon height
            borderLeft="1px solid gray" // Add a gray border starting from the same level as the black ribbon
          >
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              Report Viewer - Shiney App
            </Text>{" "}
            {/* Add title */}
            {currentIframe && (
              <Box
                width="100%"
                height="calc(100% - 32px)" // Adjust height to account for padding and title
                border="0px solid gray" // Add a gray border to the iframe box
                padding={1} // Optional padding inside the border
                flex="1" // Ensure the iframe box takes up the remaining space
              >
                <iframe
                  src={currentIframe}
                  title="Tableau Visualization"
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                />
              </Box>
            )}
          </Box>
        </HStack>
      </ChakraProvider>
    </Layout>
  );
};

export default Shineyapp;
