import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  HStack,
  Button,
  Text,
  Stack,
  Icon,
  useColorModeValue,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { FaChartBar, FaTable, FaChartPie } from "react-icons/fa";
import { Layout } from "../components/Layout";

const University = () => {
  // Set the default iframe to the first visualization
  const [currentIframe, setCurrentIframe] = useState(
    "https://public.tableau.com/views/breb-2/Dashboard12?:showVizHome=no&:embed=true"
  );

  const iconColor = useColorModeValue("black", "white"); // Define icon color based on color mode
  const ribbonWidth = useBreakpointValue({ base: "100%", md: "10%" }); // Responsive width for the ribbon
  const direction = useBreakpointValue({ base: "row", md: "column" }); // Responsive direction for the buttons stack

  const iframeList = [
    {
      src: "https://public.tableau.com/views/breb-3/Dashboard13?:showVizHome=no&:embed=true",
      title: "Chart Bar",
      icon: FaChartBar,
    },
    {
      src: "https://public.tableau.com/views/breb-2/Dashboard12?:showVizHome=no&:embed=true",
      title: "Table",
      icon: FaTable,
    },
    {
      src: "https://public.tableau.com/views/breb-4/Dashboard4?:showVizHome=no&:embed=true",
      title: "Chart Pie",
      icon: FaChartPie,
    },
  ];

  return (
    <Layout>
      <ChakraProvider>
        <HStack
          height="100vh"
          spacing={0}
          flexDirection={{ base: "column", md: "row" }} // Adjust layout for mobile and desktop
          alignItems="stretch"
        >
          <Flex
            direction={direction} // Make it horizontal on mobile and vertical on desktop
            spacing={4}
            align="stretch"
            bg="transparent"
            padding={4}
            width={ribbonWidth}
            height={{ base: "auto", md: "100vh" }}
            overflowY={{ base: "auto", md: "hidden" }}
            flexShrink={0}
          >
            {iframeList.map((iframe, index) => (
              <Button
                key={index}
                onClick={() => setCurrentIframe(iframe.src)}
                color="white"
                justifyContent="center"
                variant={currentIframe === iframe.src ? "solid" : "ghost"}
                bg={currentIframe === iframe.src ? "gray.600" : "transparent"}
                _hover={{ bg: "gray.500" }}
                _active={{ bg: "gray.400" }}
                py={7}
                width={{ base: "auto", md: "100%" }} // Make the width responsive
                mx={{ base: 2, md: 0 }} // Add margin between buttons on mobile
              >
                <Stack align="center" spacing={1}>
                  <Icon as={iframe.icon} w={6} h={6} color={iconColor} />
                  <Text fontSize="xs">{iframe.title}</Text>
                </Stack>
              </Button>
            ))}
          </Flex>
          <Box
            flex="1"
            width={{ base: "100%", md: "90%" }}
            height={{ base: "calc(100vh - 150px)", md: "100vh" }} // Adjust height for mobile
            padding={2}
            overflow="hidden"
            bg="white"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            borderLeft={{ base: "none", md: "1px solid gray" }}
          >
            {currentIframe && (
              <Box
                width="100%"
                height="100%" // Adjust height dynamically
                border="0px solid gray"
                flex="1"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <iframe
                  src={currentIframe}
                  title="Tableau Visualization"
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                />
              </Box>
            )}
          </Box>
        </HStack>
      </ChakraProvider>
    </Layout>
  );
};

export default University;
