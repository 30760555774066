import { db } from "../utils/init-firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

const PlayerCollectionRef = collection(db, "Players");

class PlayerService {
  addPlayers = (newPlayer, currentUser) => {
    const PlayerData = {
      ...newPlayer,
      createdBy: currentUser.displayName,
      dateAdded: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
    };
    return addDoc(PlayerCollectionRef, PlayerData);
  };

    static async saveData(key, data) {
    try {
      await setDoc(doc(db, "cache", key), data);
    } catch (error) {
      console.error("Error saving data: ", error);
    }
  }

  static async getData(key) {
    try {
      const docRef = doc(db, "cache", key);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error getting data: ", error);
      return null;
    }
  }

  updatePlayer = (id, updatePlayer, currentUser) => {
    const PlayerData = {
      ...updatePlayer,
      updatedBy: currentUser.displayName,
      dateUpdated: new Date().toISOString(),
    };
    const PlayerDoc = doc(db, "Players", id);
    return updateDoc(PlayerDoc, PlayerData);
  };

  deletePlayer = (id) => {
    const PlayerDoc = doc(db, "Players", id);
    return deleteDoc(PlayerDoc);
  };

  getAllPlayers = () => {
    return getDocs(PlayerCollectionRef);
  };

  getPlayer = (id) => {
    const PlayerDoc = doc(db, "Players", id);
    return getDoc(PlayerDoc);
  };
}

const playerService = new PlayerService(); 

export default playerService; 
