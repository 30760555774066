import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Text,
  Table,
  Thead,
  Tbody,
  Flex,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { auth } from "../utils/init-firebase";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Layout } from "../components/Layout";

const PlayerDataDisplay = () => {
  const [playerId, setPlayerId] = useState("");
  const [season, setSeason] = useState("");
  const [playerData, setPlayerData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFetchData = async () => {
    if (!playerId || !season) {
      setError("Please enter both Player ID and Season.");
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      const userToken = await auth.currentUser.getIdToken();
      const response = await fetch(
        `https://api-zlbmixsola-uc.a.run.app/api/players?id=${playerId}&season=${season}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error fetching data");
      }

      const data = await response.json();
      setPlayerData(data);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearData = () => {
    setPlayerId("");
    setSeason("");
    setPlayerData(null);
    setIsLoading(false);
    setError(null);
  };

  const renderPlayerDataTable = (data) => {
    if (!data || !data.response || data.response.length === 0) {
      return <Text>No player data available</Text>;
    }

    const playerInfo = data.response[0].player;
    const playerStatistics = data.response[0].statistics;

    const transformStatisticsForRadar = (stat) => {
      return [
        { subject: "Lineups", value: stat.games?.lineups || 0, fullMark: 75 },
        { subject: "Minutes", value: stat.games?.minutes || 0, fullMark: 6750 },
        { subject: "Goals", value: stat.goals?.total || 0, fullMark: 100 },
        { subject: "Assists", value: stat.goals?.assists || 0, fullMark: 100 },
        {
          subject: "Yellow Cards",
          value: stat.cards?.yellow || 0,
          fullMark: 100,
        },
        { subject: "Red Cards", value: stat.cards?.red || 0, fullMark: 100 },
        // Add more subjects as needed
      ];
    };

    return (
      <>
        <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
          <Text fontSize="lg" fontWeight="bold" mb={3}>
            Player Information:
          </Text>
          <Box maxH="300px" maxW="100%" overflow="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Photo</Th>
                  <Th>Name</Th>
                  <Th>First Name</Th>
                  <Th>Last Name</Th>
                  <Th>Age</Th>
                  <Th>Date of Birth</Th>
                  <Th>Birth Place</Th>
                  <Th>Nationality</Th>
                  <Th>Height</Th>
                  <Th>Weight</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <img
                      src={playerInfo.photo}
                      alt={playerInfo.name}
                      width="50"
                      height="50"
                    />
                  </Td>
                  <Td>{playerInfo.name}</Td>
                  <Td>{playerInfo.firstname}</Td>
                  <Td>{playerInfo.lastname}</Td>
                  <Td>{playerInfo.age}</Td>
                  <Td>{playerInfo.birth.date}</Td>
                  <Td>{playerInfo.birth.place}</Td>
                  <Td>{playerInfo.nationality}</Td>
                  <Td>{playerInfo.height}</Td>
                  <Td>{playerInfo.weight}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Box>

        <Box borderWidth="1px" borderRadius="lg" p={4}>
          <Text fontSize="lg" fontWeight="bold" mb={3}>
            Player Statistics by League:
          </Text>
          {playerStatistics.map((stat, index) => (
            <Box key={index} mb={4}>
              <Text fontWeight="bold">
                {stat.league.name} - {stat.league.season}
              </Text>
              <Box maxH="300px" maxW="100%" overflow="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Team Logo</Th>
                      <Th>Team</Th>
                      <Th>League</Th>
                      <Th>Appearances</Th>
                      <Th>Lineups</Th>
                      <Th>Minutes</Th>
                      <Th>Position</Th>
                      <Th>Goals</Th>
                      <Th>Assists</Th>
                      <Th>Yellow Cards</Th>
                      <Th>Red Cards</Th>
                      {/* Add more columns as needed */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <img
                          src={stat.team.logo}
                          alt={stat.team.name}
                          width="50"
                          height="50"
                        />
                      </Td>
                      <Td>{stat.team.name}</Td>
                      <Td>{stat.league.name}</Td>
                      <Td>{stat.games.lineups}</Td>
                      <Td>{stat.games.minutes}</Td>
                      <Td>{stat.games.position}</Td>
                      <Td>{stat.goals.total}</Td>
                      <Td>{stat.goals.assists}</Td>
                      <Td>{stat.cards.yellow}</Td>
                      <Td>{stat.cards.red}</Td>
                      {/* Add more data as needed */}
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Box>
          ))}
          {playerData &&
            playerData.response &&
            playerData.response.length > 0 && (
              <ResponsiveContainer width="100%" height={400}>
                <RadarChart
                  outerRadius={150}
                  data={transformStatisticsForRadar(
                    playerData.response[0].statistics
                  )}
                >
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" />
                  <PolarRadiusAxis angle={30} domain={[0, 100]} />
                  <Radar
                    name="Player Stats"
                    dataKey="value"
                    stroke="#8884d8"
                    fill="#8884d8"
                    fillOpacity={0.6}
                  />
                  <Legend />
                </RadarChart>
              </ResponsiveContainer>
            )}
        </Box>
      </>
    );
  };

  return (
    <Layout>
      <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
        <VStack spacing={4}>
          <FormControl id="player-id">
            <FormLabel>Player ID</FormLabel>
            <Input
              type="text"
              value={playerId}
              onChange={(e) => setPlayerId(e.target.value)}
              placeholder="Enter Player ID"
            />
          </FormControl>

          <FormControl id="season">
            <FormLabel>Season</FormLabel>
            <Input
              type="text"
              value={season}
              onChange={(e) => setSeason(e.target.value)}
              placeholder="Enter Season"
            />
          </FormControl>

          <Flex justifyContent="space-between" width="100%" mb={4}>
            <Button colorScheme="blue" onClick={handleFetchData}>
              Fetch Player Data
            </Button>
            <Button colorScheme="red" onClick={handleClearData}>
              Clear Data
            </Button>
          </Flex>
        </VStack>

        {isLoading && <Text>Loading...</Text>}
        {error && <Text color="red.500">{error}</Text>}

        {!isLoading && playerData && renderPlayerDataTable(playerData)}
      </Box>
    </Layout>
  );
};

export default PlayerDataDisplay;
