/* global grecaptcha */
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { auth } from '../utils/init-firebase';
import { Box, Button, Text, Alert, AlertIcon} from '@chakra-ui/react';
import { 
  createUserWithEmailAndPassword, signInWithEmailAndPassword, 
  sendPasswordResetEmail, onAuthStateChanged, signInWithPopup, 
  GoogleAuthProvider, signOut, confirmPasswordReset,multiFactor,MultiFactorResolver,
  sendEmailVerification, RecaptchaVerifier, signInWithPhoneNumber,linkWithCredential,
  getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator,updateProfile,
} from 'firebase/auth';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPCHA_SITE_KEY;

const AuthContext = createContext({
  currentUser: null,
  error: null,
  signInWithGoogle: () => {},
  login: () => {},
  register: () => {},
  logout: () => {},
  forgotPassword: () => {},
  resetPassword: () => {},
  initiatePhoneVerification: () => {},
  sendEmailVerification: () => {},
  handleVerificationCodeSubmission: () => {},
  initiateMultiFactorSignIn: () => {},
  completeMultiFactorSignIn: () => {}
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const containerRef = useRef(null);
  const [error, setError] = useState(null);
  const [isLinkingPhoneNumber, setIsLinkingPhoneNumber] = useState(false);
  const [isEmailVerificationPrompted, setIsEmailVerificationPrompted] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false); // New state variable
  const [loading, setLoading] = useState(true); // 1. Add a loading state
  const [countdown, setCountdown] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [linkSuccess, setLinkSuccess] = useState(false);
  const [canResendVC, setCanResendVC] = useState(true);
  
  useEffect(() => {
    if (currentUser && !currentUser.emailVerified && !isEmailVerificationPrompted) {
      const interval = setInterval(() => {
        setCountdown(prev => {
          if (prev === 1) {
            clearInterval(interval);
            setCanResend(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [currentUser, isEmailVerificationPrompted]);

  useEffect(() => {
  if (linkSuccess) {
    window.location.reload();
  }
}, [linkSuccess]);

  useEffect(() => {
    if (!isLinkingPhoneNumber && currentUser && !currentUser.emailVerified && !isEmailVerificationPrompted) {
      const intervalId = setInterval(async () => {
        await currentUser.reload();
        if (currentUser.emailVerified) {
          clearInterval(intervalId);
          setIsEmailVerificationPrompted(true); // Prevent further prompting
        }
      }, 2000); // Check every 2 seconds (adjust as needed)
    }
  }, [currentUser,isLinkingPhoneNumber, isEmailVerificationPrompted]);

  useEffect(() => {
  const intervalId = setInterval(() => {
    if (isEmailVerified) {
      // Your code to execute when isEmailVerified becomes true

      // Clear the interval once the condition is met
      clearInterval(intervalId);
    }
  }, 20); // Check every 2 seconds (adjust as needed)

  // Clear the interval when the component is unmounted
  return () => clearInterval(intervalId);
}, [isEmailVerified]);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user ? user : null);
      setLoading(false);
    }, error => {
      setError(error.message); // Set error message if there's an error during auth state change
    });

    return unsubscribe;
  }, []);

 // Use an effect to handle the unmounting of the component
 useEffect(() => {
  return () => {
    // Check if the phone number linking process is still in progress
    if (isLinkingPhoneNumber) {
      // You can add logic here to handle the situation, such as showing a warning message
      console.warn("Phone number linking is in progress. Please wait before unmounting.");
      // Prevent component from unmounting
      return false;
    }
  };
}, [isLinkingPhoneNumber]);

useEffect(() => {
  const beforeUnloadHandler = (e) => {
    if (isLinkingPhoneNumber) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  window.addEventListener('beforeunload', beforeUnloadHandler);

  return () => {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  };
}, [isLinkingPhoneNumber]);


const initializeRecaptcha = () => {
  if (!window.grecaptcha) {
    console.warn('grecaptcha is not available.');
    return;
  }

  // Check if the element with the specified ID already exists
  const existingContainer = document.getElementById('recaptcha-container');
  if (existingContainer) {
    console.warn('reCAPTCHA has already been rendered in this element.');
    return;
  }

  const element = document.createElement('div');
  element.id = 'recaptcha-container';
  document.body.appendChild(element);
  containerRef.current = element;

  grecaptcha.ready(() => {
    grecaptcha.execute(RECAPTCHA_KEY, { action: 'homepage' })
      .catch(error => {
        console.error('Error with reCAPTCHA:', error);
        setError(error); // Added error handling
      });
  });

  return () => {
    grecaptcha?.reset();
    document.body.removeChild(element);
  };
};

  useEffect(() => {
    const cleanup = initializeRecaptcha();
    return cleanup;
  }, []);

  useEffect(() => {
    console.log('The mounted user is', currentUser);
  }, [currentUser]);

  const initiateMultiFactorSignIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential;
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        const resolver = getMultiFactorResolver(auth, error);
        return resolver;
      }
      throw error;
    }
  };

  const initiatePhoneVerification = async (phoneNumber) => {
    try {
        const recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => { /* reCAPTCHA solved, allow signInWithPhoneNumber. */ }
        });
        const result = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
        if (containerRef.current) { // Check if component is still mounted
          console.log("SMS sent successfully",result);
          setConfirmationResult(result);
        }
        return result;
    } catch (error) {
        console.error("Failed to initiate phone verification", error);
        setError(error); // Added error handling
        throw error;
    }
  };

const completeMultiFactorSignIn = async (resolver, selectedIndex, verificationCode) => {
  if (resolver.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, containerRef.current);
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    return resolver.resolveSignIn(multiFactorAssertion);
  }
  throw new Error("Unsupported second factor.");
};

const handleResendVerificationEmail = async () => {
  try {
    if (canResend) {
      await sendEmailVerification(currentUser);
      alert('Verification email has been sent. Please check your email inbox.');
      setCanResend(false);
      setCountdown(60);
    } else {
      alert('Please wait for the timer to finish before resending the verification email.');
    }
  } catch (error) {
    console.error('Error sending verification email', error);
  }
};

async function handleVerificationCodeSubmission(code) {
  if (!confirmationResult) {
    throw new Error("There was no active phone verification process.");
  }
  const result = await confirmationResult.confirm(code);
  setIsVerified(true);  // set verification status to true after successful verification
  return result;
}

 async function login1(email, password) {
  try {
    // Check for multi-factor authentication
    const resolverOrCredential = await initiateMultiFactorSignIn(email, password);

    // Use getMultiFactorResolver to check for multi-factor requirement
    const resolver = getMultiFactorResolver(resolverOrCredential);
    if (resolver) {
      // Multi-factor auth is required
      const verificationCode = window.prompt("Please enter the verification code you received:");
      const userCredential = await completeMultiFactorSignIn(resolver, verificationCode);
      return userCredential;
    } else {
      // No multi-factor auth required, proceed with the received credentials
      return resolverOrCredential;
    }
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
}

function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

async function linkPhoneNumberWithUser(user, phoneNumber) {
  try {
    setIsLinkingPhoneNumber(true);

    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: (response) => {},
    });

    const phoneProvider = new PhoneAuthProvider(auth);
    let verificationId = await phoneProvider.verifyPhoneNumber(phoneNumber, recaptchaVerifier);

    while (true) {
      const verificationCode = window.prompt("Please enter the verification code you received via SMS (or type RESEND to get a new code):");

      if (verificationCode === "RESEND") {
        if (canResendVC) {
          setCanResendVC(false);
          verificationId = await phoneProvider.verifyPhoneNumber(phoneNumber, recaptchaVerifier);
          setTimeout(() => setCanResendVC(true), 20000); // 20 seconds delay
          continue;
        } else {
          window.alert("You can resend the verification code after 20 seconds.");
          continue;
        }
      }

      if (verificationCode) {
        try {
          const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
          const linkResult = await linkWithCredential(user, phoneCredential);

          if (linkResult.user) {
            console.log("Phone number linked successfully");
            setIsVerified(true);
            setLinkSuccess(true);
            break;
          } else {
            throw new Error("Failed to link phone number with user.");
          }
        } catch (error) {
          console.error("Verification failed, please try again.", error);
        }
      } else {
        console.error("Verification code is missing.");
      }
    }
  } catch (error) {
    console.error("Failed to link phone number:", error);
    await deleteUser(user);
    setIsVerified(false);
  } finally {
    setIsLinkingPhoneNumber(false);
  }
}

const cleanupRecaptcha = () => {
  if (containerRef.current) {
    grecaptcha.reset(); // Reset reCAPTCHA
    document.body.removeChild(containerRef.current); // Remove the reCAPTCHA container
    containerRef.current = null;
  }
};

  async function updateUserProfile(user, displayName) {
    try {
      await updateProfile(user, {
        displayName: displayName
      });
    } catch (error) {
      console.error("Failed to update user profile:", error);
      throw error;
    }
  }
  

  async function register(email, password, displayName, phoneNumber) {
  try {
    clearError(); 
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    if (userCredential.user) {
      await updateUserProfile(userCredential.user, displayName);
      await sendEmailVerification(userCredential.user);

      while (true) {
        await userCredential.user.reload();
        if (userCredential.user.emailVerified) {
          setIsEmailVerified(true);
          break;
        }
      }
      await new Promise(resolve => setTimeout(resolve, 10));

      await linkPhoneNumberWithUser(userCredential.user, phoneNumber);
      return { success: true, user: userCredential.user };
    } else {
      await deleteUser(userCredential.user);
      return { success: false, error: "User registration failed." };
    }
  } catch (error) {
    console.error("Registration failed:", error);
    // setError(error.message); // Remove this line to avoid setting the error state here
    return { success: false, error: error.message };
  }
}

   async function deleteUser(user) {
    try {
      await user.delete();
      console.log("User account deleted successfully.");
    } catch (error) {
      console.error("Error deleting user account:", error);
      setError(error.message); // Set error message if there's an error deleting the user
    }
  }

  function forgotPassword(email) {
    return sendPasswordResetEmail(auth, email, {
      url: `${window.location.origin}/login`,
    }).catch(error => {
      setError(error.message); // Set error message if forgot password fails
      throw error;
    });
  }

  function resetPassword(oobCode, newPassword) {
    return confirmPasswordReset(auth, oobCode, newPassword).catch(error => {
      setError(error.message); // Set error message if reset password fails
      throw error;
    });
  }
   function logout() {
    cleanupRecaptcha();
    signOut(auth)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Logout failed:", error);
        setError(error.message); // Set error message if logout fails
      });
  }

  function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
      .catch(error => {
        setError(error.message); // Set error message if Google sign-in fails
        throw error;
      });
  }

  function clearError() {
  setError(null);
}

  if (linkSuccess) {
  return (
    <Box 
      border="1px" 
      borderRadius="md" 
      padding="5" 
      boxShadow="lg" 
      width="sm" 
      margin="auto" 
      mt={5}
    >
      <Alert 
        status="success" 
        variant="subtle" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        textAlign="center" 
      >
        <AlertIcon boxSize="40px" mr={0} />
        <Text mb={4} fontSize="lg">
          Phone number linked successfully! You will be redirected shortly.
        </Text>
      </Alert>
    </Box>
  );
}

  if (isEmailVerified) {
    return (
      <Box 
        border="1px" 
        borderRadius="md" 
        padding="5" 
        boxShadow="lg" 
        width="sm" 
        margin="auto" 
        mt={5}
      >
        <Alert 
          status="success" 
          variant="subtle" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          textAlign="center" 
        >
          <AlertIcon boxSize="40px" mr={0} />
          <Text mb={4} fontSize="lg">
            Your email has been verified successfully! Please await an SMS code for the next step.
          </Text>
        </Alert>
      </Box>
    );
  }

  if (currentUser && !currentUser.emailVerified && !isEmailVerificationPrompted) {
    return (
      <Box 
        border="1px" 
        borderRadius="md" 
        padding="5" 
        boxShadow="lg" 
        width="sm" 
        margin="auto" 
        mt={5}
      >
        <Alert 
          status="info" 
          variant="subtle" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          textAlign="center" 
        >
          <AlertIcon boxSize="40px" mr={0} />
          <Text mb={4} fontSize="lg">
            Your email is not yet verified. Please check your email and click the verification link.
          </Text>
          <Button 
  variant="solid" 
  colorScheme="blue" 
  onClick={handleResendVerificationEmail}
  isDisabled={!canResend}
>
  {canResend ? 'Resend Verification Email' : `Resend in ${countdown}s`}
</Button>
        </Alert>
      </Box>
    );
  }
  
  const value = {
    currentUser,
    signInWithGoogle,
    clearError,
    login,
    register,
    deleteUser,
    loading,
    logout,
    forgotPassword,
    resetPassword,
    sendEmailVerification,
    handleVerificationCodeSubmission,
    initiateMultiFactorSignIn,
    completeMultiFactorSignIn,
    initiatePhoneVerification,
    isVerified,
    error,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}