import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react";
import customTheme from "./Theme"; // Import the custom theme

// Configuration for color mode
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// Extend the custom theme with color mode configuration
const theme = extendTheme({ ...customTheme, config });

const root = document.getElementById("root");
const appRoot = createRoot(root);

appRoot.render(
  <ChakraProvider theme={theme}>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </ChakraProvider>
);
